
export default function CheckReferalStatusCode(statusCode) {

    if(statusCode == 0){
        return "In Active"
    }
    else if(statusCode == 1){
        return "Registered"
    }
    else if(statusCode == 2){
        return "Applied"
    }
    else if(statusCode == 3){
        return "Redeemed"
    }
    else{
        return "Pending"
    }

}