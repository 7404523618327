import React from 'react'

export default function CheckPaymentMethod(paymentMethod) {

    if(paymentMethod == 0){
        return "Cash on delivery"
    }
    else{
        return "Online"
    }

}

const PAYMENT_Method = Object.freeze({
    cod: 0,
    online: 1
  })

const ORDER_CHARGES_WAIVED = Object.freeze({
HANDLING: 1,
DELIVERY: 2
})