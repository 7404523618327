import React, { useContext } from "react";
import Colors from "../../utils/colors/Colors";
import { ThemeContext } from "../../context/Theme";
import { useNavigate } from "react-router-dom";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
  const { systemThemeMode, appColorTheme, setAppColorTheme } =
    useContext(ThemeContext);
  const colors =
    Colors[appColorTheme == "systemDefault" ? systemThemeMode : appColorTheme];
  const navigateScreenTo = useNavigate();

  let footerClass = `shadow-lg bg-${colors?.navColor} shadow-slate-500 dark:bg-${colors?.navColor}`;
  return (
    <>
      {
        <footer
          style={{ backgroundColor: Colors.colorTheme[colors?.navColor] }}
          className={footerClass}
        >
          <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
            <div className="mb-6 md:mb-0">
              <div href="#" className="flex items-center">
                {/* <img src={AppLogo} className="h-8 me-3" alt="NativeNest" /> */}
                <img
                  className="h-10 sm:h-10 md:h-14 lg:h-20 xl:h-20 w-auto"
                  src={
                    process.env.REACT_APP_PLACEHOLDER_IMAGE
                    // "https://res.cloudinary.com/nrootsdev/image/upload/v1718003097/logos/NativeNestAnd512.png"
                  }
                  alt="Logo"
                />
                {/* <span className="self-center text-appThemeColorVeryDark text-2xl font-semibold whitespace-nowrap">
                  NativeNest
                </span> */}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-5">
              <div>
                <text
                  className={`mb-6 block text-sm font-semibold text-gray-900 uppercase dark:text-${colors?.heading}  text-${colors?.heading}`}
                >
                  Company
                </text>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateScreenTo("/ContactUs")}
                      className="hover:underline"
                    >
                      About
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <text
                  className={`mb-6 block text-sm font-semibold text-gray-900 uppercase dark:text-${colors?.heading}  text-${colors?.heading}`}
                >
                  Help center
                </text>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <div href="#" className="hover:underline">
                      Twitter
                    </div>
                  </li>
                  <li className="mb-4">
                    <div href="#" className="hover:underline">
                      Facebook
                    </div>
                  </li>
                  <li className="mb-4">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateScreenTo("/ContactUs")}
                      className="hover:underline"
                    >
                      Contact Us
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <text
                  className={`mb-6 block text-sm font-semibold text-gray-900 uppercase dark:text-${colors?.heading}  text-${colors?.heading}`}
                >
                  Legal
                </text>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateScreenTo("/PrivacyPolicy")}
                      className="hover:underline"
                    >
                      Privacy Policy
                    </div>
                  </li>
                  {/* <li className="mb-4">
                            <div href="#" className="hover:underline">Licensing</div>
                        </li> */}
                  <li className="mb-4">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateScreenTo("/TermsAndConditions")}
                      className="hover:underline"
                    >
                      Terms &amp; Conditions
                    </div>
                  </li>
                  <li className="mb-4">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateScreenTo("/RefundAndReturnPolicy")}
                      className="hover:underline"
                    >
                      Refund &amp; Return Policy
                    </div>
                  </li>
                  <li className="mb-4">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateScreenTo("/ShippingPolicy")}
                      className="hover:underline"
                    >
                      Shipping &amp; Delivery Policy
                    </div>
                  </li>
                  {/* <li className="mb-4">
                            <div href="#" className="hover:underline">FAQs</div>
                        </li>
                        <li className="mb-4">
                            <div href="#" className="hover:underline">Security</div>
                        </li> */}
                </ul>
              </div>
              <div>
                <text
                  className={`mb-6 block text-sm font-semibold text-gray-900 uppercase dark:text-${colors?.heading}  text-${colors?.heading}`}
                >
                  Top Categories
                </text>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  {/* <li className="mb-4">
                            <div style={{cursor:'pointer'}}  onClick={()=>navigateScreenTo("/Categories")} className="hover:underline">General</div>
                        </li> */}
                  <li className="mb-4">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateScreenTo("/Categories")}
                      className="hover:underline"
                    >
                      Categories
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <text
                  className={`mb-6 block text-sm font-semibold text-gray-900 uppercase dark:text-${colors?.heading}  text-${colors?.heading}`}
                >
                  Download
                </text>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <a
                      href="https://www.apple.com/in/app-store/"
                      target="_blank"
                      className="hover:underline"
                    >
                      iOS
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://play.google.com/store/apps?hl=en-IN"
                      target="_blank"
                      className="hover:underline"
                    >
                      Android
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex justify-center">
              <div
                href="#"
                className="text-gray-500  hover:text-appThemeColorVeryDark ms-5 transition-transform ease-in-out hover:scale-150 cursor-pointer duration-200 active:scale-50"
              >
                <FaFacebookF className="text-xxs sm:text-xs md:text-md  lg:text-lg  xl:text-xl" />
                <span className="sr-only">Facebook page</span>
              </div>
              <div
                href="#"
                className="text-gray-500  hover:text-appThemeColorVeryDark ms-5 transition-transform ease-in-out hover:scale-150 cursor-pointer duration-200 active:scale-50"
              >
                <FaLinkedinIn className="text-xxs sm:text-xs md:text-md  lg:text-lg  xl:text-xl" />
                <span className="sr-only">Linkedin community</span>
              </div>
              <div
                href="#"
                className="text-gray-500  hover:text-appThemeColorVeryDark ms-5 transition-transform ease-in-out hover:scale-150 cursor-pointer duration-200 active:scale-50"
              >
                <FaTwitter className="text-xxs sm:text-xs md:text-md  lg:text-lg  xl:text-xl" />
                <span className="sr-only">Twitter page</span>
              </div>
              <div
                href="#"
                className="text-gray-500  hover:text-appThemeColorVeryDark ms-5 transition-transform ease-in-out hover:scale-150 cursor-pointer duration-200 active:scale-50"
              >
                <FaInstagramSquare className="text-xxs sm:text-xs md:text-md  lg:text-lg  xl:text-xl" />
                <span className="sr-only">Instagram account</span>
              </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div
              className={`flex items-center justify-center text-sm text-${colors?.heading} sm:text-center dark:text-${colors?.heading}  cursor-pointer`}
            >
              © 2024 &nbsp;
              <a
                href="https://nativeroot.in/"
                target="_blank"
                className={`hover:text-appThemeColorVeryDark transition-transform ease-in-out duration-200 active:scale-95`}
              >
                NativeNest Powered by Native Roots Retail Private Limited.
                &nbsp;
              </a>
              All Rights Reserved.
            </div>
          </div>
        </footer>
      }
    </>
  );
}
