import React from 'react';
import ReactDOM from 'react-dom/client'; // Correct import statement
import './index.css';
import App from './App';
import { CookiesProvider } from "react-cookie";
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from "@apollo/client";
import SetupApolloClient from "./apollo/apolloIndex";
import './translations/i18nest-config/i18next'

function Main() {

  const client = SetupApolloClient();

  return (
    <React.StrictMode>
        <CookiesProvider>
          <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
        </CookiesProvider>
    </React.StrictMode>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
