import React, { useContext, useState } from 'react'
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import { SendCustomerSupportEmail} from '../../apollo/mutations';
import { toast } from 'react-toastify';
import { useMutation, gql, useQuery } from "@apollo/client";
import Loader from '../../ui-components/Loader/Loader';


const SENDMAIL = gql`
  ${SendCustomerSupportEmail}
`;


export default function ContactUs() {

  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];  
  const {t} = useTranslation()
  const [SendMail] = useMutation(SENDMAIL, { onCompleted, onError });
    var [name,setName]= useState()
    var [phone,setPhone]= useState()
    var [email,setEmail]= useState()
    var [reason,setReason]= useState()
    var [subject,setSubject]= useState()
    var [loading,setloading]= useState(false)

  async function onCompleted(data) {
  //console.log("🚀 ~ 22 onCompleted ~ data:", data)
}

async function onError(data) {
  //console.log("🚀 26 ~ onError ~ data:", data)
}


  const sendEmail = async()=>{
    if ( subject && reason && phone && name) {
        loading = true
        setloading(loading)
        let requestBody = {  
            "name": name?.trim(),
            "phone": phone?.trim()?("+91"+phone?.trim()):'',  
            "email": email?.trim(),
            "reason": reason?.trim(),
            "subject": subject?.trim()
          }
        try{
            let data = await SendMail({ variables:requestBody})
            toast.success("Request Successfully submitted")
            loading = false
            setloading(loading)
            //console.log("🚀 ~ sendEmail ~ data:", data)
            let empty = ''
            setSubject(empty)
            setName(empty)
            setPhone(empty)
            setEmail(empty)
            setReason(empty)
            setSubject(empty)
        }
        catch(error){
            loading = false
            setloading(loading)
            //console.log("🚀 ~ sendEmail ~ error:", error)
            toast.error(error.message)
        }
    }
    else{
        let message = 'Mandatory Fields Missing'
        toast.error(message)
    }
    
  }

  return (
    <>
        <div className={`pb-1p p-0.5p shadow-md rounded bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>

        <div className='flex flex-col gap-5 items-center justify-center py-5p'>
            <h1 className={`text-2xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-3xl font-bold text-center mb-2 text-${colors?.heading}`}>Contact Us</h1>
            <div className={`flex flex-col gap-5 shadow-md rounded p-8 w-full max-w-4xl p-4 rounded shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
            <div className="flex items-center">
                <label htmlFor="dropdown" className={`mb-2 text-sm font-medium text-${colors?.heading} sr-only`}>How can we help you? *</label>
                <div className="relative w-full ">
                    <select
                        id="dropdown"
                        name="dropdown"
                        value={subject}
                        onChange={(event)=>{let inputValue = event?.target?.value;setSubject(inputValue);}}
                        style={{ color: colors?.textColor,backgroundColor:Colors.colorTheme[colors?.navColor] }}
                        className={`text-${colors?.textColor} block line-clamp-1 p-2 z-20 w-full text-xs sm:text-sm bg-gray-50 border border-${colors?.buttonBackgroundDark} outline-none hover:border-2 rounded focus:ring-${colors?.buttonBackgroundDark} focus:border-${colors?.buttonBackgroundDark} dark:bg-gray-50 dark:border-${colors?.buttonBackgroundDark} placeholder-gray-400 placeholder:font-normal`}
                        required
                    >
                        <option className={`text-${colors?.textColor}  line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="" disabled>
                            How can we help you? *
                        </option>
                        <option className={`text-${colors?.heading} line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="I need help with my NativeNest online order">I need help with my NativeNest online order.</option>
                        <option className={`text-${colors?.heading} line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="I need help to delete my NativeNest account">I need help to delete my NativeNest account.</option>
                        <option className={`text-${colors?.heading} line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="I found incorrect/outdated information on a page">I found incorrect/outdated information on a page.</option>
                        <option className={`text-${colors?.heading} line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="There is a photo/review that is bothering me and I would like to report it">There is a photo/review that is bothering me and I would like to report it.</option>
                        <option className={`text-${colors?.heading} line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="The website/app are not working the way they should">The website/app are not working the way they should.</option>
                        <option className={`text-${colors?.heading} line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="I would like to give feedback/suggestions">I would like to give feedback/suggestions.</option>
                        <option className={`text-${colors?.heading} line-clamp-2  text-xs sm:text-sm rounded p-5 m-5 `} style={{backgroundColor:Colors.colorTheme[colors?.navColor]}} value="Other">Other</option>
                    </select>
                </div>
            </div>
                <div className="flex items-center">
                    <label htmlFor="Full-Name" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Full Name:</label>
                    <div className="relative w-full">
                        <input value={name} type="text" onChange={(event)=>{let inputValue = event?.target?.value;setName(inputValue);console.log(inputValue)}} id="Full-Name" aria-describedby="helper-text-explanation" style={{backgroundColor:Colors.colorTheme[colors?.navColor],color:Colors.colorTheme[colors?.heading]}} className={`text-${colors?.heading} block p-2.5 w-full z-20  text-xs sm:text-sm text-gray-900 bg-gray-50 border border-appThemeColorDark outline-none hover:border-2 appearance-none rounded border border-appThemeColor focus:ring-appThemeColorDark focus:border-appThemeColorDark dark:bg-gray-50 dark:border-s-appThemeColorDark border-appThemeColorDark dark:border-appThemeColorDark dark:placeholder-gray-400 dark:text-white dark:focus:border-appThemeColorDark placeholder:tracking-normal placeholder:font-normal`} placeholder="Enter Your Full Name *" required />
                    </div>
                </div>
                <div className="flex items-center">
                    <label htmlFor="Email-Address" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Email Address:</label>
                    <div className="relative w-full">
                        <input type="text" value={email} onChange={(event)=>{let inputValue = event?.target?.value;setEmail(inputValue);console.log(inputValue)}} id="Email-Address" aria-describedby="helper-text-explanation" style={{backgroundColor:Colors.colorTheme[colors?.navColor],color:Colors.colorTheme[colors?.heading]}} 
                        className={`text-${colors?.heading} block p-2.5 w-full z-20 text-xs sm:text-sm text-gray-900 bg-gray-50 border border-appThemeColorDark outline-none hover:border-2 appearance-none rounded border border-appThemeColor focus:ring-appThemeColorDark focus:border-appThemeColorDark dark:bg-gray-50 dark:border-s-appThemeColorDark border-appThemeColorDark dark:border-appThemeColorDark dark:placeholder-gray-400 dark:text-white dark:focus:border-appThemeColorDark placeholder:tracking-normal placeholder:font-normal`} placeholder="Enter Your Email Address *" required />
                    </div>
                </div>
                <div className="flex items-center">
                    <div id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" className="bg-appThemeColorVeryLight flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4  text-xs sm:text-sm font-medium text-center text-gray-900 border border-appThemeColor rounded-s-lg focus:ring-4 cursor-default outline-none ring-appThemeColorDark dark:bg-appThemeColorDark dark:text-white dark:border-appThemeColorDark border-appThemeColor" type="button">
                        +91
                    </div>
                    <label htmlFor="Mobile-Number" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Mobile Number:</label>
                    <div className="relative w-full">
                        <input type="text" value={phone} onChange={(event)=>{let inputValue = event?.target?.value;setPhone(inputValue);console.log(inputValue)}} id="Mobile-Number" aria-describedby="helper-text-explanation" style={{backgroundColor:Colors.colorTheme[colors?.navColor],color:Colors.colorTheme[colors?.heading]}} className={`text-${colors?.heading} block p-2.5 w-full z-20 text-xs sm:text-sm text-gray-900 bg-gray-50 border border-appThemeColorDark outline-none appearance-none border border-appThemeColor focus:ring-appThemeColorDark focus:border-appThemeColorDark dark:bg-gray-50 dark:border-s-appThemeColorDark border-appThemeColorDark dark:border-appThemeColorDark dark:placeholder-gray-400 dark:text-white dark:focus:border-appThemeColorDark placeholder:tracking-normal placeholder:font-normal`} placeholder="Enter Your Mobile Number (Optional)" pattern="[0-9]{10}" />
                    </div>
                </div>
                <div className="flex items-center">
                    <label htmlFor="text-input" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Message/Comments:</label>
                    <div className="relative w-full">
                        <textarea type="text" value={reason} onChange={(event)=>{let inputValue = event?.target?.value;setReason(inputValue);console.log(inputValue)}} id="text-input" aria-describedby="helper-text-explanation" style={{backgroundColor:Colors.colorTheme[colors?.navColor],color:Colors.colorTheme[colors?.heading]}} className={`text-${colors?.heading} block p-2.5 w-full z-20 text-xs sm:text-sm text-gray-900 bg-gray-50 border border-appThemeColorDark outline-none hover:border-2 appearance-none rounded border border-appThemeColor focus:ring-appThemeColorDark focus:border-appThemeColorDark dark:bg-gray-50 dark:border-s-appThemeColorDark border-appThemeColorDark dark:border-appThemeColorDark dark:placeholder-gray-400 dark:text-white dark:focus:border-appThemeColorDark placeholder:tracking-normal placeholder:font-normal`} 
                        placeholder="Type Reason/Comments *" required />
                    </div>
                </div>
                 <div className='flex flex-row justify-center items-center mt-2' >
                    <button
                        id="submit"
                        type="button"
                        disabled={!(subject && reason && phone && name) || loading}
                        onClick={sendEmail}
                        className=" disabled:opacity-50 disabled:cursor-not-allowed text-sm p-2 sm:text-md md:text-md lg:text-lg xl:text-lg w-1/4 inline-flex justify-center whitespace-nowrap rounded bg-appThemeColorDark text-sm font-medium text-white shadow-sm shadow-appThemeColorDark-950/10 hover:bg-appThemeColorDark transition duration-200 transform active:scale-95"
                    >
                        {t('submit')}
                    </button>
                </div>
            </div>
            <div className={` shadow-md rounded p-8 w-full max-w-4xl p-4 rounded shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                <>
                    {/* <h1 className={`text-md sm:text-md md:text-xl lg:text-xl xl:text-xl font-bold text-center mb-8 text-${colors?.heading}`}>Info</h1> */}
                    <div className="flex flex-col md:flex-row justify-between">
                    <div className="mb-8 md:mb-0 md:mr-4">
                        <h2 className={`text-xl sm:text-xl md:text-xl lg:text-xl xl:text-xl font-semibold mb-4 text-${colors?.heading}`}>NATIVE ROOTS RETAIL PRIVATE LIMITED</h2>
                        <p className="text-sm text-left" style={{ color: colors?.heading , textAlign: 'justify'}}>
                        No.53, Kaveri Layout, Hebbal, Kempapura
                        <br />
                        Hebbal Kempapura, Bangalore North, Bangalore - 560024, Karnataka
                        </p>
                    </div>
                    <div className="md:ml-4">
                        <h2 className={`text-xl sm:text-xl md:text-xl lg:text-xl xl:text-xl font-semibold mb-4 text-${colors?.heading}`}>Contact Details</h2>
                        <p className="text-sm text-left" style={{ color: colors?.heading , textAlign: 'justify'}}>
                        {/* <strong>Phone:</strong> (123) 456-7890
                        <br /> */}
                        <strong>Email:</strong> info@nativeroot.in
                        </p>
                    </div>
                    </div>
                </>
            </div>
        </div>
        <Loader loading={loading} />
        {/* <Footer/> */}
        </div>
    </>
  )
}
