export default function CheckRefundStatus(refundStatus) {

    if(refundStatus == 0){
        return "Not Applicable"
    }
    else if(refundStatus == 1){
        return "Refund Pening"
    }
    else if(refundStatus == 2){
        return "Refund Initiated"
    }
    else if(refundStatus == 3){
        return "Refund Scuccess"
    }
    else if(refundStatus == 4){
        return "Refund Failed"
    }
    else{
        return "Refund Applicable"
    }

}

// const PHONEPAY_REFUND_STATUS = Object.freeze({
//     NOT_APPLICABLE: 0,
//     REFUND_PENDING: 1,
//     REFUND_INITIATED: 2,
//     REFUND_SUCCESS: 3,
//     REFUND_FAILED: 4,
//     REFUND_APPLICABLE: 5
//   })