import React, { useContext, useState } from 'react'
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import Footer from '../Footer/Footer';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import { motion } from 'framer-motion';

export default function ShippingPolicy() {

  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
  //console.log("🚀 ~ PrivacyPolicy ~ systemThemeMode:", systemThemeMode)
  //console.log("🚀 ~ PrivacyPolicy ~ colors:", colors)
  var [data,setData] = useState(
    {
        title:'Shipping & Delivery Policy',
        description:'The Company shall provide delivery of the Products upon purchase during within 24 hours as communicated to You through the Platform. The Company endeavours to show the estimated delivery time for every order, however, the Company does not guarantee the delivery within the said time, since the exact delivery time of each order may vary due to various factors such as availability of third party delivery service providers, demand, traffic and weather conditions, a force majeure event, etc,. Further, You can check the estimated time of arrival (ETA), when You go to the homepage of the App even before placing an order, to ensure that You are aware of the ETA before proceeding with placing an order. You agree that the Company reserves the right to charge You, fee for rain, peak hours and/or very high demand, late night fee, packing handling charges, convenience/platform fee, small cart fee, any other fee in addition to the delivery fee towards the delivery of the orders, which you can see on the view bill section before check out page on the App.',
        details:[
            {
                info:['a. The delivery of the Products will be made to the delivery address specified by You while placing the order for the Products on the Platform. You are solely responsible for providing a complete and correct delivery address and, if applicable, further instructions for delivery. The Company shall not be responsible for any delay in delivering the order placed by You on the Platform caused due to the incorrect or incomplete address provided by You.', 
                ]
            },
            {
                info:['b. The goods will ordinarily be delivered when an appropriate person is able to receive the order at the address provided by You. If You request to leave a delivery unattended at Your address, You expressly disclaim the Company from all the liabilities which may arise by virtue of the Products being left unattended for a period of time. This includes but is not limited to theft, tampering, contamination, and the result of any change in temperature of the items which need to be kept chilled or frozen.',
            ]
            },
            {
                info:['c. In the event You opt to make payment for the Products by ‘Cash on Delivery (CoD)’ mode, the Company shall have the right to refuse delivery of the ordered Products to You if You fail to make the complete payment and the Company shall treat such order as cancelled order and shall not be liable for any losses or damage that may arise on account of such non – delivery to You. The Company shall reserve the right to charge You, the delivery fee any other fees for such cancelled order and recover the same, at its sole discretion towards the cost incurred on such delivery attempt.'
            ]
            },
        ]
    }
  )

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
        <div className={`pb-1p p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
        <div className="overflow-y-auto p-2.5">
            {/* <div className={`p-4 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                <h1 className="font-bold text-lg mb-1.5" style={{ color: colors?.heading }}>{data?.title}</h1>
                <p className="text-sm text-justify" style={{ color: colors?.heading }}>{data?.description}</p>
            </div> */}
              <h2 className="font-bold text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl text-center" style={{ color: colors?.heading }}>Shipping & Delivery Policy</h2>
              <motion.h1 
                    className="text-4xl font-bold text-center "
                    initial={{ y: -450 }}
                    animate={{ y: 0 }}
                    transition={{ delay: 0.3, type: 'spring', stiffness: 120 }}
                >
                    {/* Welcome */}
                    <Lottie className="w-46 sm:w-32 md:w-52 lg:w-96 xl:w-96 mx-auto text-center " animationData={require('../../assets/ShippingPolicy.json')} />
                </motion.h1>
            <div className={`p-4 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                {/* <h1 className="font-bold text-lg mb-1.5" style={{ color: colors?.heading }}>{data?.title}</h1> */}
                <p className="text-sm text-justify" style={{ color: colors?.heading }}>{data?.description}</p>
            </div>
            {
                data.details.map((item, index) => (
                    <div key={`_${index}PrivacyPolicy`} className={`p-4 mt-2.5 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                        {/* <h2 className="font-bold text-lg" style={{ color: colors?.heading }}>{item?.heading}</h2> */}
                        {
                            item?.info.map((value, index) => (
                                <p key={`_${index}PrivacyPolicy1`} className="text-left text-sm mt-1.5" style={{ color: colors?.heading }}>{value}</p>
                            ))
                        }
                    </div>
                ))
            }
        </div>
        {/* <Footer/> */}
        </div>
    </>
  )
}
