import React,{useCallback, useContext,useEffect,useMemo,useState} from 'react'
import { CartContext } from '../../context/Cart'
import { QueryProductDetailsByProductId, QueryDefaultCustomerType , QueryProductsBySubCategory, QueryCatalogProducts} from "../../apollo/queries";
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LocationContext } from '../../context/Location';
import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import UseCookie from '../../utils/cookies/useCookie';
import { FaCrown } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import Footer from '../Footer/Footer';
import GroceryItemCard from '../../ui-components/Cards/GroceryItemCard';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import PremuimBanner from '../../ui-components/PremuimBanner/PremuimBanner';
import DOMPurify from 'dompurify';

const QUERYPRODUCTDETAIL = gql`
  ${QueryProductDetailsByProductId}
`;

const getDefaultCustomerType = gql`
  ${QueryDefaultCustomerType}
`;

const QUERYPRODUCTSBYSUBCATEGORIES = gql`
  ${QueryProductsBySubCategory}
`;

const QUERYCATALOGPRODUCTS = gql`
  ${QueryCatalogProducts}
`;

export default function ProductDetails() {
  const { cartItems, addToCart,removeFromCart,callCartData } = useContext(CartContext)
  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme]; 
  const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();
  const { t } = useTranslation()
  const { location } = useContext(LocationContext)
  const navigateScreenTo = useNavigate();
  const navigationLocation = useLocation();
  const { id } = useParams();
  var [cartData, setCartData] = useState([]);
  var [totalCartItems, setTotalCartItems] = useState([]);
  var [totalDiscountPrice, setTotalDiscountPrice] = useState([]);
  var [totalActualPrice, setTotalActualPrice] = useState([]);
  var [itemdetails,setItemDetails] = useState("")
  var [productId,setproductId] = useState('')
  var [refreshing, setRefreshing] = useState(false);
  var [isLoading, setIsLoading] = useState(false);
  var [subCategoryId,SetSubCategoryId] =useState("")
  var [catalogId,SetCatalogId] =useState("")
  var [catelogIdlist,setCatelogIdlist] = useState([])
  var [loginInfo,setLoginInfo] = useState()
  var [premuimBanner,setPremuimBanner] = useState(false)
  var [data,setdata] = useState({})
  var [catalogProducts,setcatalogProducts] = useState({})
  var [similarProducts,setsimilarProducts] = useState({})
  var [generalCustomerType, setGeneralCustomerType] = useState(false);
  const client = useApolloClient();
  var [defaultCustomerType,setDefaultCustomerType] = useState()


  useEffect(() => {
    if(navigationLocation?.state?.itemId || navigationLocation?.state?.productId || id){
      productId = navigationLocation?.state?.itemId || navigationLocation?.state?.productId || id
      setproductId(productId)
      // //console.log("welcome--------------------")
      reloadProductDetailsdata()
    }
    else{
      navigateScreenTo('/')
    }
}, [navigationLocation?.state,id,location])




const onRefetchProductDetails = async (fetchMoreResult) => {
  //console.log("🚀 ~ App ~ fetchMoreResult:", fetchMoreResult)
   if (fetchMoreResult) {
     await verifyLoginInfo()
       subCategoryId = fetchMoreResult?.queryProductDetailsByProductId?.category?.subcategories[0]?._id
       SetSubCategoryId(subCategoryId)
       catalogId = fetchMoreResult?.queryProductDetailsByProductId?.catalog?._id
       SetCatalogId(catalogId);
       itemdetails = {
           "itemId": fetchMoreResult?.queryProductDetailsByProductId?._id,
           "name": fetchMoreResult?.queryProductDetailsByProductId?.name,
           "description": fetchMoreResult?.queryProductDetailsByProductId?.description,
           "image": fetchMoreResult?.queryProductDetailsByProductId?.defaultImage,
           "images": fetchMoreResult?.queryProductDetailsByProductId?.images,
           "outOfStock": fetchMoreResult?.queryProductDetailsByProductId?.outOfStock,
           "subCategoriesId" : fetchMoreResult?.queryProductDetailsByProductId?.category?.subcategories?._id,
           "mrp": fetchMoreResult?.queryProductDetailsByProductId?.sku?.mrp,
           "sellingPrice": fetchMoreResult?.queryProductDetailsByProductId?.sku?.sellingPrice,
           "prices": fetchMoreResult?.queryProductDetailsByProductId?.sku?.prices,
           "discount": fetchMoreResult?.queryProductDetailsByProductId?.sku?.discount,
           "sortOrder": 1,
           "itemType": "PRODUCT",
           "skuId": fetchMoreResult?.queryProductDetailsByProductId?.sku?._id,
           "defaultCustomerType":defaultCustomerType,
           "isLocationServicable":fetchMoreResult?.queryProductDetailsByProductId?.isLocationServicable
         };
       setItemDetails(itemdetails)
       refreshing = false
       setRefreshing(refreshing)
       isLoading = false
       setIsLoading(isLoading)
       if(subCategoryId){
           let requestBody =   {
            "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
            "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
            "rows": 1,
            "columns": 20,
            "subCategoryId": subCategoryId
          }
          const { data, loading: loadingCatalogProducts, error: CatalogError } = await client.query({
            query: QUERYPRODUCTSBYSUBCATEGORIES,
            variables:requestBody,
            fetchPolicy: 'network-only',
          });
          if(loginInfo){
            generalCustomerType = await getCustomCookie('generalCustomerType')
            console.log("🚀 ~ onCompleted:async ~ generalCustomerType:", generalCustomerType)
            setGeneralCustomerType(generalCustomerType)
          } 
          similarProducts = data
          setsimilarProducts(similarProducts)
       }
       if(catalogId){
           let requestBody = {
            "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
            "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
            "catalogId": catalogId
          }
           const { data, loading: loadingCatalogProducts, error: CatalogError } = await client.query({
            query: QUERYCATALOGPRODUCTS,
            variables:requestBody,
            fetchPolicy: 'network-only',
          });
          catalogProducts = data
          setcatalogProducts(catalogProducts)
          if(catalogProducts?.queryCatalogProducts?.customerProducts.length>0){
            //console.log("At Item Details",catalogProducts?.queryCatalogProducts?.isLocationServicable)
            setCustomCookie('isLocationServicable', JSON.stringify(catalogProducts?.queryCatalogProducts?.isLocationServicable))
            catelogIdlist = catalogProducts?.queryCatalogProducts?.customerProducts?.map(data=>{return data?._id})
            setCatelogIdlist(catelogIdlist)
          }
       }
   }
 }

const openPremuimBanner = useCallback(async (value) => {
  premuimBanner = value
  setPremuimBanner(premuimBanner)
},[])


const reloadProductDetailsdata = async () => {
  try {
    isLoading = true
    setIsLoading(isLoading)
    refreshing = true
    setRefreshing(refreshing)
    await verifyLoginInfo()
    let requestBody =  {  
      "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
      "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"), 
      "productId": "" + productId
    }
    const { data, loading, error } = await client.query({
      query: QUERYPRODUCTDETAIL,
      variables:requestBody,
      fetchPolicy: 'network-only',
    });
    let details = data
    setdata(details)
    await onRefetchProductDetails(data)
    refreshing = false
    setRefreshing(refreshing)
    isLoading = false
    setIsLoading(isLoading)
  } catch (error) {
    console.error('Error while refreshing categories:', error);
  }
}

useEffect(() => {
  cartData = cartItems?.items
  setCartData(cartData,null,cartItems)
  // totalCartItems = cartData?.reduce((noTotalItems,item)=>noTotalItems+item?.quantity,0)
  totalCartItems = cartData?.length
  setTotalCartItems(totalCartItems)
  // totalDiscountPrice = cartItems?.billDetails?cartItems?.billDetails?.grandTotal:cartData?cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.sellingPrice),0):0
  // totalDiscountPrice = cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.sellingPrice),0)
  // setTotalDiscountPrice(totalDiscountPrice)
  // totalDiscountPriceCalculation()
  // totalActualPrice = cartItems?.billDetails?cartItems?.billDetails?.itemsMrp:cartData?cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.mrp),0):0
  totalActualPrice = cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.mrp),0)
  setTotalActualPrice(totalActualPrice)
  // itemdetails = props.route.params
  // // // //console.log("🚀 ~ useEffect ~ itemdetails:",cartItems?.cartobj)
  // setItemDetails(itemdetails)
}, [ navigationLocation?.state,id,cartItems ])

const totalDiscountPriceCalculation = async ()=>{
  let totalDiscountPrice = 0
  let loginInfo = await getCustomCookie('loginInfo')
  let defaultCustomerType =  await getCustomCookie('defaultCustomerType') // if not login we use the default customer type that come from homepage api 
  for(let index=0;index<cartData?.length;index++){
    let item = cartData[index]
    let priceDetailOfAllCustomerTypes = await priceDetailAsObject(item?.prices);
    totalDiscountPrice =  totalDiscountPrice+item?.quantity*Number(priceDetailOfAllCustomerTypes[loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType])
  }
  setTotalDiscountPrice(totalDiscountPrice)
 }

const verifyLoginInfo = async()=>{
  loginInfo = await getCustomCookie('loginInfo')
  setLoginInfo(loginInfo)
  // let defaultCustomerType1 =  await getCustomCookie('defaultCustomerType') // if not login we use the default customer type that come from homepage api 
  // console.log("🚀 ~ verifyLoginInfo ~ defaultCustomerType1:", defaultCustomerType1)
  const { data } = await client.query({
    query: getDefaultCustomerType,
    variables:{
      "longitude": "77.59104670126453",
      "latitude": "13.04269368886535",
    },
    fetchPolicy: 'network-only',
  });
  let defaultCustomerType = data?.queryDefaultCustomerType?.customerType // if not login we use the default customer type 
  setDefaultCustomerType(defaultCustomerType)
}

React.useEffect(() => {
  initialLoad();
}, []);

 const initialLoad = async()=>{
  await verifyLoginInfo()
  let locationInfo = await getCustomCookie('locationInfo')
  console.log("🚀 ~ initialLoad ~ locationInfo:", locationInfo)
  await callCartData(cartItems,loginInfo,location)
 }

const fetchProductDetails = (itemId) => {
  //console.log("🚀 ~ fetchProductDetails ~ itemId:", itemId)
  productId = itemId
  setproductId(productId)
  reloadProductDetailsdata()
}

const priceDetailAsObject = (prices) => {
  let data =  prices?.reduce((priceDetailOfAllCustomerTypes, { customerType, sellingPrice })=>{priceDetailOfAllCustomerTypes[customerType] = sellingPrice;return priceDetailOfAllCustomerTypes},{})
  return data
}

const ItemsListing = ({item,ListItemIndex}) => {
  return <>
    <div className='bg-white m-1 rounded-lg cursor-pointer'>
      <GroceryItemCard openPremuimBanner={openPremuimBanner} generalCustomerType={generalCustomerType} isLocationServicable={similarProducts?.queryProductsBySubCategory?.isLocationServicable} customerType={loginInfo?.customerType!=undefined?loginInfo?.customerType:0} cartItems={cartItems} addToCart={addToCart} removeFromCart={removeFromCart} key={ListItemIndex} item={item} />
    </div>
  </>
}

// Use useMemo to memoize the result of the expensive calculation
const priceDetailOfAllCustomerTypes = useMemo(() => priceDetailAsObject(itemdetails?.prices), [itemdetails]); //itemdetails?.sellingPrice

const Catalogs = ({item}) => {
  // console.log("🚀 ~ Catalogs ~ item:", item)
  let customerType = loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType

  // Use useMemo to memoize the result of the expensive calculation
  const priceDetailOfAllCustomerTypes = useMemo(() => priceDetailAsObject(item?.sku?.prices), [item]);

  // // console.log("🚀 ~ Catalogs ~ item:",JSON.stringify(item))
  item = {
      "itemId": item?._id,
      "name": item?.name,
      "measureUnit": item?.sku?.measure + " "+item?.sku?.unit,
      "description": item?.description,
      "image": item?.defaultImage,
      "images": item?.images,
      "subCategoriesId" : item?.category?.subcategories?._id,
      "mrp": item?.sku?.mrp,
      "sellingPrice": priceDetailOfAllCustomerTypes[customerType],   //sellingPrice,
      "discount": item?.sku?.discount,
      "sortOrder": 1,
      "itemType": "PRODUCT",
      "skuId": item?.sku?._id,
      ...item
  }
  // // //console.log("🚀 ~ Catalogs ~ item:", item)
  return <>
    <div 
        onClick={() => fetchProductDetails(item?.itemId)} 
        className={`relative cursor-pointer flex flex-col justify-between dealsContainer m-1p rounded`} 
        style={{ backgroundColor:itemdetails?.itemId == item?.itemId?Colors.colorTheme[colors?.categorySelected]:Colors.colorTheme[colors?.backgroundColor],borderColor: Colors.colorTheme[colors?.buttonBackground],borderWidth:itemdetails?.itemId == item?.itemId ?'1px':'0px', }}
      >
        <div className='p-5 sm:p-4 md:p-4 lg:p-4 xl:p-5 pt-6 sm:pt-5 md:pt-5 lg:pt-5 xl:pt-6'>
          {item?.measureUnit && 
            <text 
              className={`text-center text-appThemeColorDark text-sm `} 
              style={{ color:itemdetails?.itemId === item?.itemId?Colors.colorTheme[colors?.heading]:Colors.colorTheme[colors?.borderColor] }}
            >
              {item.measureUnit}
            </text>
          }

          <div className="flex items-center mt-0 pt-1.25">
            <text 
              className={`font-bold text-sm sm:text-sm md:text-md lg:text-lg`} 
              style={{ color:itemdetails?.itemId === item?.itemId?Colors.colorTheme[colors?.heading]:Colors.colorTheme[colors?.borderColor] }}
            >
              ₹{item?.sellingPrice}&nbsp;
            </text>
            <text 
              className={`line-through text-sm sm:text-sm md:text-md lg:text-md`} 
              style={{ color:itemdetails?.itemId === item?.itemId?Colors.colorTheme[colors?.heading]:Colors.colorTheme[colors?.borderColor] }}
            >
               ₹{item?.mrp} 
            </text>
          </div>
        </div>

        {item?.sellingPrice !== item?.mrp && (
          <div className="absolute left-0 top-0  items-center justify-center bg-appThemeColorDark rounded-sm px-2 sm:px-1 md:px-1 lg:px-2">
            <span className={`text-${colors?.textWhite ?? 'white'} text-xs sm:text-xxs lg:text-xs md:text-xs xl:text-xs font-bold`}>
              {(((item?.mrp - item?.sellingPrice) / item?.mrp) * 100).toFixed(0)} % off
            </span>
          </div>
        )}

      </div>
  </>
}

const addToCartAction = async()=>{
  let loginInfo = await getCustomCookie('loginInfo')
  let locationInfo = await getCustomCookie('locationInfo')
  addToCart({...itemdetails,quantity:0},NaN,cartItems,data?.queryProductDetailsByProductId?.isLocationServicable,loginInfo, locationInfo)
}

const handleRemoveFromCart = async()=>{
  let loginInfo = await getCustomCookie('loginInfo')
  let locationInfo = await getCustomCookie('locationInfo')
  removeFromCart({...itemdetails,quantity:cartItems['cartobj'][itemdetails?.itemId ?? itemdetails?.productId]?.quantity},NaN,cartItems, data?.queryProductDetailsByProductId?.isLocationServicable,loginInfo, locationInfo)
}

const handleAddToCart = async()=>{
  let loginInfo = await getCustomCookie('loginInfo')
  let locationInfo = await getCustomCookie('locationInfo')
  addToCart({...itemdetails,quantity:cartItems['cartobj'][itemdetails?.itemId ?? itemdetails?.productId]?.quantity},NaN,cartItems, data?.queryProductDetailsByProductId?.isLocationServicable,loginInfo, locationInfo)
}

    //Only works when customer is a general or With skip login
    const getPremuimPrice = (AllCustomerTypes, generalCustomerType) => {
      for (let customerType in AllCustomerTypes) {
        if (customerType != generalCustomerType) {
          return AllCustomerTypes[customerType];
        }
      }
      return null; // or some other default value or action if no other key exists
    };

  return (
    <>
      <div className={` bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg} shadow-md rounded-lg `}>
        <div className='flex flex-col-reverse sm:flex-col-reverse md:flex-row lg:flex-row xl:flex-row justify-between'> 
          <div className="relative md:overflow-y-scroll lg:overflow-y-scroll hide-scroll-bar  md:border-r lg:border-r xl:border-r md:border-b lg:border-b  border-gray-200 md:max-h-90vh lg:max-h-90vh sm:w-auto md:w-1/2 lg:w-1/2 xl:w-1/2">
            {priceDetailOfAllCustomerTypes && priceDetailOfAllCustomerTypes[loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType] != itemdetails?.mrp && (
              <div className="z-10 hidden sm:hidden md:block lg:block absolute  items-center justify-center bg-appThemeColorDark rounded-sm px-2 sm:px-1 md:px-1 lg:px-2">
                <span className={`text-${colors?.textWhite ?? 'white'} text-xs sm:text-sm lg:text-sm md:text-md xl:text-md font-bold`}>
                  {(((itemdetails?.mrp - (priceDetailOfAllCustomerTypes[loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType])) / itemdetails?.mrp) * 100).toFixed(0)} % off
                </span>
              </div>
            )}
            {loginInfo?.customerType == 1 && priceDetailOfAllCustomerTypes && priceDetailOfAllCustomerTypes[1] !== 0 && priceDetailOfAllCustomerTypes[0] != itemdetails?.mrp && (
                <FaCrown className="hidden sm:hidden md:block lg:block absolute right-0 top-0 text-yellow-600 text-md sm:text-md md:text-xl  lg:text-xl  xl:text-xxl mr-1 mt-1"  />
            )}
            {itemdetails?.image && (<div className="container mx-auto relative hidden sm:hidden md:block lg:block w-full h-1/3 sm:h-1/3 md:h-1/2 lg:h-1/2 py-5 border-b border-gray-200  rounded-lg overflow-hidden" >
                 {itemdetails?.images?.length>1?<Slider {...{
                    dots: true,
                    infinite: true,
                    speed: 1000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                  }}>
                    {itemdetails.images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          className="object-contain mx-auto" // Ensure images are centered horizontally
                          alt={`Item ${index + 1}`}
                        />
                      </div>
                    ))}
                  </Slider>:
                <img src={itemdetails?.image} className=" w-full h-full rounded object-contain" alt="Item"/>}
                {itemdetails?.outOfStock &&  <div className="absolute inset-0 flex items-center w-full flex justify-center">
                  <span className="w-full bg-white bg-opacity-40 backdrop-blur-sm text-md sm:text-md md:text-md lg:text-md xl:text-md lg:font-bold xl:font-bold text-red-500 text-center p-1">
                    {t('OutofStock')}
                  </span>
                </div>}
                </div>)} 
            <div className='p-2 sm:p-3 md:m-4 lg:m-5 md:border-b-0 lg:border-b-0 border-gray-200 border-b sm-border-b'>
              <div className={`my-2 text-md sm:text-md md:text-xl lg:text-2xl xxl:text-2xl font-semibold text-${colors?.heading}`}>Product Description</div>
              <div className={`mt-4 text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl text-${colors?.heading}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(itemdetails?.description) }} />
              {/* <div className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md text-gray-500">Original cola flavour: Savor the refreshing cola flavor that is deliciously uplifting.</div> */}
            </div>

          </div>
          <div className="relative md:overflow-y-scroll lg:overflow-y-scroll hide-scroll-bar md:border-r lg:border-r sm:border-b-0 md:border-b lg:border-b  border-gray-200 md:max-h-90vh lg:max-h-90vh sm:w-auto md:w-1/2 lg:w-1/2 xl:w-1/2">
              {priceDetailOfAllCustomerTypes && priceDetailOfAllCustomerTypes[loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType] != itemdetails?.mrp && (
                  <div className="sm:block md:hidden lg:hidden absolute  items-center justify-center bg-appThemeColorDark rounded-sm px-2 sm:px-1 md:px-1 lg:px-2">
                    <span className={`text-${colors?.textWhite ?? 'white'} text-xs sm:text-sm lg:text-sm md:text-md xl:text-md font-bold`}>
                      {(((itemdetails?.mrp - (priceDetailOfAllCustomerTypes[loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType])) / itemdetails?.mrp) * 100).toFixed(0)} % off
                    </span>
                  </div>
                )}
              {loginInfo?.customerType == 1 && priceDetailOfAllCustomerTypes && priceDetailOfAllCustomerTypes[1] !== 0 && priceDetailOfAllCustomerTypes[0] != itemdetails?.mrp && (
                <FaCrown className="sm:block md:hidden lg:hidden absolute right-0 top-0 text-yellow-600 text-md sm:text-md md:text-xl  lg:text-xl  xl:text-xxl mr-1 mt-1"  />
              )}
              <div className='container mx-auto sm:block md:hidden lg:hidden'>{itemdetails?.image && (<div className="reltaive w-full h-1/3 sm:h-1/3 md:h-1/2 lg:h-1/2 py-5 rounded  border-b border-gray-200  overflow-hidden" >
                {itemdetails?.images?.length>1?<Slider {...{
                    dots: true,
                    infinite: true,
                    speed: 1000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                  }}>
                    {itemdetails.images.map((image, index) => (
                      <div key={index} className="px-2">
                        <img
                          src={image}
                          className="object-contain mx-auto" // Ensure images are centered horizontally
                          alt={`Item ${index + 1}`}
                        />
                      </div>
                    ))}
                  </Slider>:
                  <img src={itemdetails?.image} className=" w-full h-full rounded-lg object-contain" alt="Item"/>}
                {itemdetails?.outOfStock &&  <div className="absolute inset-0 flex items-center w-full flex justify-center">
                  <span className="w-full bg-white bg-opacity-40 backdrop-blur-sm text-md sm:text-md md:text-md lg:text-md xl:text-md lg:font-bold xl:font-bold text-red-500 text-center p-1">
                    {t('OutofStock')}
                  </span>
                </div>}
              </div>)} 
              </div>
              <div className='p-2 sm:p-3 md:m-4 lg:m-5'>
                <div className={`mt-5 text-md sm:text-md md:text-xl lg:text-2xl xxl:text-2xl font-semibold text-${colors?.heading}`}>{itemdetails?.name}</div>
                {/* {itemdetails?.quantity && <div className='text-left flex justify-left items-left'><text className='text-appThemeColorDark text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl '>{itemdetails?.quantity}</text></div>} */}
                {!(typeof generalCustomerType == 'number') && priceDetailOfAllCustomerTypes && (getPremuimPrice(priceDetailOfAllCustomerTypes,(loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType))!=0) && (
                    <button onClick={(e) =>{openPremuimBanner(true)}} className="bg-gradient-to-r from-yellow-600 via-yellow-400 to-yellow-600 text-yellow-900 rounded p-1 hover:opacity-90 focus:outline-none transition duration-200 transform active:scale-90">
                      <div className="flex justify-around items-center">
                        <FaCrown className="text-xxs sm:text-xxs md:text-xxs  lg:text-sm  xl:text-sm text-yellow-900" />
                        <text className={`text-xxxs sm:text-xxxs md:text-xxxs  lg:text-sm  xl:text-sm  lg:font-bold xl:font-bold px-1 font-bold `}>Get for ₹{getPremuimPrice(priceDetailOfAllCustomerTypes,(loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType))}</text>
                        <MdKeyboardArrowDown className="text-sm sm:text-sm md:text-sm  lg:text-lg  xl:text-lg  text-yellow-900 pt-0.5" />
                      </div>
                    </button>
                  )}
                {itemdetails?.mrp && <div className="flex flex-row justify-between ">
                    <div className="text-left my-1 sm:mb-0"> {/* Add sm:mb-0 to make sure it aligns correctly on small screens */}
                      {priceDetailOfAllCustomerTypes && <span className={`text-appThemeColorDark text-sm sm:text-sm md:text-md lg:text-xl xl:text-xxl font-bold mr-1 text-${colors?.heading}`}>₹{(typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[loginInfo?.customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType]}  </span>}
                      {typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[loginInfo?.customerType] != 0 && priceDetailOfAllCustomerTypes[generalCustomerType] != itemdetails?.mrp  && <span className={`text-appThemeColorDark  text-xs sm:text-sm md:text-sm lg:text-md xl:text-lg mr-1 line-through text-${colors?.heading}`}>₹{priceDetailOfAllCustomerTypes[generalCustomerType]}</span>}
                      {((typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[loginInfo?.customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[(loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType)]) != itemdetails?.mrp && <span className={`text-appThemeColorDark text-xs sm:text-sm md:text-sm lg:text-md xl:text-lg line-through text-${colors?.heading}`}>{itemdetails?.mrp}</span>}
                    </div>
                    {(!cartItems?.cartobj || !cartItems?.cartobj?.[itemdetails?.itemId ?? itemdetails?.productId]?.quantity) ? (
                      <button
                        onClick={addToCartAction}
                        disabled={itemdetails?.outOfStock}
                        className={`w-1/3 bg-appThemeColorVeryDark text-white text-center flex justify-center items-center rounded ${(itemdetails?.outOfStock)?'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-200 transform active:scale-90'}`}
                      >
                        Add
                      </button>
                    ) : (
                      <div className="flex flex-row w-1/3 bg-appThemeColorDark text-white text-center justify-around items-center rounded">
                        <button className="pl-1 text-white text-md font-bold opacity-100 cursor-pointer transition duration-200 transform active:scale-50" onClick={handleRemoveFromCart}>-</button>
                        <span className="text-white text-xs sm:text-xs md:text-sm  lg:text-sm  xl:text-sm  font-bold">{cartItems['cartobj'][itemdetails?.itemId ?? itemdetails?.productId]?.quantity}</span>
                        <button className="pr-1 text-white text-md font-bold ${(itemdetails?.outOfStock)?'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-200 transform active:scale-50'}" onClick={handleAddToCart}>+</button>
                      </div>
                    )}
                  </div>}
                  <div>

                  </div>
              </div>
              <hr></hr>
              {catalogProducts?.queryCatalogProducts && catalogProducts?.queryCatalogProducts?.customerProducts?.length > 1 && (
                <>
                  <div className="p-2 sm:p-3 md:m-4 lg:m-5">
                    <text className={`text-black text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl font-semibold text-${colors?.heading}`}>
                      {t('SelectUnit')}
                    </text>
                    <div className="flex flex-wrap">
                      {catalogProducts?.queryCatalogProducts?.customerProducts?.map((item, index) => (
                        <Catalogs key={index} item={item} />
                      ))}
                    </div>
                  </div>
                </>
              )}
              <hr></hr>
              <div className='p-2 sm:p-3 md:m-4 lg:m-5 hidden sm:hidden md:block lg:block'>
                <text className={`text-black text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl font-semibold text-${colors?.heading}`}>Why shop from NativeNest?</text>
                <div>
                    <div className={`mt-4 text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl text-${colors?.heading}`}>Superfast Delivery</div>
                    <div className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md text-gray-500">Get your order delivered to your doorstep at the earliest from dark stores near you.</div>
                </div>
                <div>
                    <div className={`mt-4 text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl text-${colors?.heading}`}>Best Prices & Offers</div>
                    <div className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md text-gray-500">Best price destination with offers directly from the manufacturers.</div>
                </div>
                <div>
                    <div className={`mt-4 text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl text-${colors?.heading}`}>Wide Assortment</div>
                    <div className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md text-gray-500">Choose from 5000+ products across food with different categories.</div>
                </div>
              </div>
              {/* <hr></hr> */}
          </div>
        </div>
        {premuimBanner && <PremuimBanner Colors={Colors} colors={colors} banner={premuimBanner} openPremuimBanner={openPremuimBanner} />}
        <div className='p-2 sm:p-3 md:m-4 lg:m-5'>
          <text className={`text-black text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl font-semibold text-${colors?.heading}`}>Similar Products</text><br></br>
          <div className='flex snap-x mt-2 overflow-x-auto justify-left hide-scroll-bar '>
              {similarProducts?.queryProductsBySubCategory?.displayItems?.items.map((item, index) => (
                  !catelogIdlist.includes(item?.itemId) && <div key={index + item?.itemId}><ItemsListing item={item} ListItemIndex={index} /></div>
              ))}
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    </>
  )
}
