export const QueryCustomerLandingPage = `query QueryCustomerLandingPage($longitude: String!, $latitude: String!, $device: Int!) {
  queryCustomerLandingPage(longitude: $longitude, latitude: $latitude, device: $device) {
    customer {
      _id
      name
      phone
      email
      gender
      nativePlace
      customerType
      isActive
      jwtToken
      refreshToken
      addresses {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      status
      permittedPaymentOptions {
        method
        label
      }
      selectedPaymentMethod
      cart {
        items {
          productId
          skuId
          name
          description
          image
          quantity
          mrp
          status
          prices {
            customerType
            sellingPrice
          }
        }
        deliveryAddress {
          _id
          deliveryAddress
          details
          label
          selected
          isActive
        }
        billDetails {
          itemsTotal
          deliveryCharge
          couponDiscount
          grandTotal
          totalSaving
          handlingCharge
          itemSavings
          itemsMrp
          chargesWaived
        }
      }
      GST
    }
    displayItems {
      displayName
      sortOrder
      rows
      columns
      items {
        itemId
        name
        description
        image
        images
        mrp
        prices {
          customerType
          sellingPrice
        }
        discount
        sortOrder
        itemType
        skuId
        outOfStock
        offerEnabled
        offerImage
      }
    }
    isLocationServicable
    premiumMembershipMessage
  }
}`

export const QueryReferrelsHistories = `query QueryReferrelsHistories($skip: Int!, $limit: Int!) {
  queryReferrelsHistories(skip: $skip, limit: $limit) {
    referrelsHistory {
      storeId
      code
      type
      referrerName
      referrer
      referrerPhone
      referee
      refereePhone
      refereeName
      orderId
      appliedDate
      registeredDate
      percentageApplied
      redeemedDate
      status
    }
    totalCount
  }
}`

export const ViewCart = `query QueryCart($longitude: String!, $latitude: String!) {
  queryCart(longitude: $longitude, latitude: $latitude) {
    cart {
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
        packingChecked
        billingChecked
      }
      deliveryAddress {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      billDetails {
        itemsTotal
        deliveryCharge
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
        chargesWaived
        couponDiscount
        walletInfo {
          applied
          amount
          message
        }
        couponInfo {
          applied
          amount
          code
          message
          cancellationApplied
        }
        nestInfo {
          applied
          amount
          message
          couponRefundAmount
          couponRefundAccomodated
        }
        cancelledInfo {
          nestInfo {
            applied
            amount
            message
            couponCancellationAmount
            couponCancellationAccomodated
          }
        }
      }
    }
    status
  }
}`

export const QueryCategories = `query QueryCategories($longitude: String!, $latitude: String!, $rows: Int!, $columns: Int!) {
  queryCategories(longitude: $longitude, latitude: $latitude, rows: $rows, columns: $columns) {
    displayName
    sortOrder
    rows
    columns
    items {
      itemId
      name
      description
      image
      images
      mrp
      prices {
        customerType
        sellingPrice
      }
      discount
      sortOrder
      itemType
      skuId
    }
  }
}`

export const QuerySubCategories = `query QuerySubCategories($longitude: String!, $latitude: String!, $rows: Int!, $columns: Int!, $categoryId: String!) {
  querySubCategories(longitude: $longitude, latitude: $latitude, rows: $rows, columns: $columns, categoryId: $categoryId) {
    displayName
    sortOrder
    rows
    columns
    items {
      itemId
      name
      description
      image
      images
      mrp
      prices {
        customerType
        sellingPrice
      }
      discount
      sortOrder
      itemType
      skuId
    }
  }
}`


export const QueryProductsBySubCategory = `query QueryProductsBySubCategory($longitude: String!, $latitude: String!, $rows: Int!, $columns: Int!, $subCategoryId: String!) {
  queryProductsBySubCategory(longitude: $longitude, latitude: $latitude, rows: $rows, columns: $columns, subCategoryId: $subCategoryId) {
    displayItems {
      displayName
      sortOrder
      rows
      columns
      items {
        itemId
        name
        description
        image
        images
        mrp
        prices {
          customerType
          sellingPrice
        }
        discount
        sortOrder
        itemType
        skuId
        outOfStock
      }
    }
    isLocationServicable
  }
}`

export const QueryProductsSearch = `query QueryProductsSearch($longitude: String!, $latitude: String!, $text: String!, $skip: Int!, $limit: Int!) {
  queryProductsSearch(longitude: $longitude, latitude: $latitude, text: $text, skip: $skip, limit: $limit) {
    customer {
      _id
      name
      phone
      customerType
      isActive
      jwtToken
      refreshToken
      addresses {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      status
      permittedPaymentOptions {
        method
        label
      }
      selectedPaymentMethod
      cart {
        items {
          productId
          skuId
          name
          description
          image
          quantity
          mrp
          status
        }
        deliveryAddress {
          _id
          deliveryAddress
          details
          label
          selected
          isActive
        }
        billDetails {
          itemsTotal
          deliveryCharge
          couponDiscount
          grandTotal
          totalSaving
          handlingCharge
          itemSavings
          itemsMrp
        }
      }
    }
    displayItems {
      displayName
      sortOrder
      rows
      columns
      items {
        itemId
        name
        description
        image
        images
        mrp
        prices {
          customerType
          sellingPrice
        }
        discount
        sortOrder
        itemType
        skuId
        outOfStock
      }
    }
    isLocationServicable
  }
}`

export const QueryConfirmNewAddress = `query Query($confirmNewAddressInput: ConfirmNewAddressInput) {
  queryConfirmNewAddress(confirmNewAddressInput: $confirmNewAddressInput)
}`;

export const QueryAllOrders = `query QueryAllOrders($limit: Int!, $skip: Int!) {
  queryAllOrders(limit: $limit, skip: $skip) {
    totalCount
    orders {
      _id
      customerId
      orderDate
      deliveryDate
      completionTime
      itemsCount
      orderStatus
      orderAmount
      localStore
      centralStore
      deliveryAddress {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
    }
  }
}`

export const QueryCustomerAddress = `query QueryCustomerAddress {
  queryCustomerAddress {
    _id
    location {
      coordinates
    }
    deliveryAddress
    details
    label
    selected
    isActive
  }
}`

export const GetOrderDetailsById = `query GetOrderDetailsById($orderId: String!) {
  getOrderDetailsById(orderId: $orderId) {
    _id
    items {
      productId
      skuId
      name
      description
      image
      quantity
      mrp
      prices {
        customerType
        sellingPrice
      }
      status
      packingChecked
      billingChecked
    }
    customerType
    customer {
      _id
      name
      phone
      email
      gender
      nativePlace
      customerType
      isActive
      jwtToken
      refreshToken
      addresses {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      status
      permittedPaymentOptions {
        method
        label
      }
      selectedPaymentMethod
      cart {
        items {
          productId
          skuId
          name
          description
          image
          quantity
          mrp
          prices {
            customerType
            sellingPrice
          }
          status
          packingChecked
          billingChecked
        }
        deliveryAddress {
          _id
          location {
            coordinates
          }
          deliveryAddress
          details
          label
          selected
          isActive
        }
        billDetails {
          itemsTotal
          deliveryCharge
          grandTotal
          totalSaving
          handlingCharge
          itemSavings
          itemsMrp
          chargesWaived
          couponDiscount
          walletInfo {
            applied
            amount
            message
          }
          couponInfo {
            applied
            amount
            code
            message
            cancellationApplied
          }
          nestInfo {
            applied
            amount
            message
            couponRefundAmount
            couponRefundAccomodated
          }
          cancelledInfo {
            nestInfo {
              applied
              amount
              message
              couponCancellationAmount
              couponCancellationAccomodated
            }
          }
        }
      }
      GST
    }
    paymentStatus
    paymentMethod
    paidAmount
    orderAmount
    orderDate
    deliveryDate
    billDetails {
      itemsTotal
      deliveryCharge
      grandTotal
      totalSaving
      handlingCharge
      itemSavings
      itemsMrp
      chargesWaived
      couponDiscount
      walletInfo {
        applied
        amount
        message
      }
      couponInfo {
        applied
        amount
        code
        message
        cancellationApplied
      }
      nestInfo {
        applied
        amount
        message
        couponRefundAmount
        couponRefundAccomodated
      }
      cancelledInfo {
        nestInfo {
          applied
          amount
          message
          couponCancellationAmount
          couponCancellationAccomodated
        }
      }
    }
    deliveryAddress {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    isOrderModified
    refundStatus
    orderState {
      status
      workerStatus
      ppWorkerAssigned
      ppWorkerAssignedEmail
      billingWorkerAssigned
      billingWorkerAssignedEmail
      riderAssigned
      riderAssignedEmail
    }
    invoiceId
    deliveredImage
  }
}`;

export const QueryAvailableProducts = `query QueryAvailableProducts($longitude: String!, $latitude: String!, $skip: Int!, $limit: Int!) {
  queryAvailableProducts(longitude: $longitude, latitude: $latitude, skip: $skip, limit: $limit) {
    displayItems {
      displayName
      sortOrder
      rows
      columns
      items {
        itemId
        name
        description
        image
        images
        mrp
        prices {
          customerType
          sellingPrice
        }
        discount
        sortOrder
        itemType
        skuId
        outOfStock
      }
    }
    totalCount
    isLocationServicable
  }
}`;

export const QueryOutOfStockProducts = `query QueryOutOfStockProducts($longitude: String!, $latitude: String!, $skip: Int!, $limit: Int!) {
  queryOutOfStockProducts(longitude: $longitude, latitude: $latitude, skip: $skip, limit: $limit) {
    displayItems {
      displayName
      sortOrder
      rows
      columns
      items {
        itemId
        name
        description
        image
        images
        mrp
        prices {
          customerType
          sellingPrice
        }
        discount
        sortOrder
        itemType
        skuId
      }
    }
    totalCount
    isLocationServicable
  }
}`

export const QueryProductDetailsByProductId = `query QueryProductDetailsByProductId($latitude: String!, $longitude: String!, $productId: String!) {
  queryProductDetailsByProductId(latitude: $latitude, longitude: $longitude, productId: $productId) {
    _id
    name
    description
    aliases
    images
    defaultImage
    isActive
    category {
      _id
      name
      isActive
      images
      subcategories {
        _id
        name
        description
        isActive
        images
        categoryId
      }
      description
    }
    catalog {
      _id
      name
      description
      isActive
      parcelable
    }
    sortOrder
    hsn
    isNewProduct
    displayName
    sku {
      _id
      productId
      discount
      barcode
      isActive
      mrp
      prices {
        customerType
        sellingPrice
      }
      unit
      measure
    }
    isLocationServicable
    outOfStock
    offerImage
    offerEnabled
  }
}`

export const QueryCatalogProducts = `query QueryCatalogProducts($latitude: String!, $longitude: String!, $catalogId: String!) {
  queryCatalogProducts(latitude: $latitude, longitude: $longitude, catalogId: $catalogId) {
    customerProducts {
      _id
      name
      description
      aliases
      images
      defaultImage
      isActive
      category {
        _id
        name
        isActive
        images
        subcategories {
          _id
          name
          description
          isActive
          images
          categoryId
        }
        description
      }
      catalog {
        _id
        name
        description
        isActive
        parcelable
      }
      sortOrder
      hsn
      isNewProduct
      displayName
      sku {
        _id
        productId
        discount
        barcode
        isActive
        mrp
        prices {
          customerType
          sellingPrice
        }
        unit
        measure
      }
      offerImage
      offerEnabled
    }
    isLocationServicable
  }
}`

export const isLocationAccessible = `query Query($longitude: String!, $latitude: String!) {
  isLocationAccessible(longitude: $longitude, latitude: $latitude)
}`

export const QueryBillingDetails = `query QueryBillingDetails {
  queryBillingDetails {
    itemsTotal
    deliveryCharge
    couponDiscount
    grandTotal
    totalSaving
    handlingCharge
    itemSavings
    itemsMrp
  }
}`

export const QueryOrderTransactionStatus = `query QueryOrderTransactionStatus($transactionId: String!) {
  queryOrderTransactionStatus(transactionId: $transactionId) {
    onlinePaymentStatus
    paymentStatus
    orderId
    orderStatus
    amount
  }
}`

export const QueryAllCustomerTypes = `query GetAllCustomerTypes {
  getAllCustomerTypes {
    name
    _id
    customerType
    description
    charges {
      handling {
        waiverAmount
        rate
      }
      delivery {
        waiverAmount
        rate
      }
    }
    default
  }
}`

export const QueryCustomerDetails = `query QueryCustomer {
  queryCustomer {
    _id
    name
    phone
    email
    gender
    nativePlace
    customerType
    isActive
    jwtToken
    refreshToken
    addresses {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    status
    permittedPaymentOptions {
      method
      label
    }
    selectedPaymentMethod
    cart {
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
      }
      deliveryAddress {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      billDetails {
        itemsTotal
        deliveryCharge
        couponDiscount
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
        chargesWaived
      }
    }
    GST
  }
}`

export const QueryNestTransactions = `query QueryNestTransactions($skip: Int!, $limit: Int!) {
  queryNestTransactions(skip: $skip, limit: $limit) {
    customerId
    balance
    transactions {
      _id
      type
      accountingType
      orderId
      amount
      reason
      description
      nestBalance
      createdAt
      expiry
      expired
    }
    createdAt
    expiry
    totalTxnCount
  }
}`

export const QueryWalletTransactions = `query QueryWalletTransactions($skip: Int!, $limit: Int!) {
  queryWalletTransactions(skip: $skip, limit: $limit) {
    customerId
    balance
    transactions {
      type
      orderId
      amount
      reason
      description
      balance
      createdAt
    }
    createdAt
    totalTxnCount
    message
  }
}`

export const QueryDefaultCustomerType = `query QueryDefaultCustomerType($latitude: String!, $longitude: String!) {
  queryDefaultCustomerType(latitude: $latitude, longitude: $longitude) {
    name
    _id
    customerType
    description
    charges {
      handling {
        waiverAmount
        rate
      }
      delivery {
        waiverAmount
        rate
      }
    }
    default
  }
}`