import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import UseCookie from '../utils/cookies/useCookie';

const SetupApolloClient = () => {
  const { getCustomCookie } = UseCookie();
  const cache = new InMemoryCache();
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_SERVER_URL}/graphql/`,
    // uri: `${process.env.REACT_APP_TEST_SERVER_URL}/graphql/`,
    fetchOptions: {
      redirect: 'follow', // Ensures the fetch follows redirects
    },
  });

  const request = async (operation) => {
    //console.log("🚀 ~ request ~ operation:", operation?.operationName)
    const existingHeaders = operation.getContext().headers || {};
    console.log("🚀 ~ request ~ existingHeaders:", existingHeaders)
    if (!existingHeaders.Authorization) {
      let loginInfo = await getCustomCookie("loginInfo")
      if(loginInfo?.jwtToken){
        operation.setContext({
          headers: {
            Authorization: loginInfo?.jwtToken ? `Bearer ${loginInfo?.jwtToken}` : "",
          },
        });
      }
    }
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle;
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            let loginInfo = getCustomCookie("loginInfo")
            if(operation.operationName == 'QueryCart' && !loginInfo?.jwtToken){
              return
            }
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const client = new ApolloClient({
    link: ApolloLink.from([requestLink, httpLink]),
    cache,
    resolvers: {},
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
    connectToDevTools: true,
  });

  return client;
};

export default SetupApolloClient;
