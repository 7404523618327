import React, { useContext, useEffect, useState } from 'react'
import { LocationContext } from '../../context/Location';
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
import { CartContext } from '../../context/Cart';
import { useTranslation } from 'react-i18next';
import { RiBarChart2Fill } from "react-icons/ri";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaListAlt } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { RiShoppingBag4Fill } from "react-icons/ri";

export default function CheckOut(props) {
    const { t } = useTranslation()
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];     
    const { cartItems } = useContext(CartContext)
    var [cartData, setCartData] = useState([]);
    var [totalCartItems, setTotalCartItems] = useState([]);
    var [totalDiscountPrice, setTotalDiscountPrice] = useState([]);
    var [totalActualPrice, setTotalActualPrice] = useState([]);
    var [loading, setloading] = useState(false);


  useEffect(() => {
    cartData = cartItems?.items?cartItems?.items:[]
    setCartData(cartData)
    totalCartItems = ""+(cartData?.length?cartData?.length:0)
    setTotalCartItems(totalCartItems)
    totalDiscountPriceCalculation()
    totalActualPrice = cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*Number(item?.mrp),0)
    setTotalActualPrice(""+totalActualPrice)
    props?.setIsLoading(false)
  }, [cartItems])

  const totalDiscountPriceCalculation = async ()=>{
    let totalDiscountPrice = 0
    let data = []
    for(let index=0;index<cartData?.length;index++){
      let item = cartData[index]
      let priceDetailOfAllCustomerTypes = await props?.priceDetailAsObject(item?.prices);
      data.push({...item,priceDetails:priceDetailOfAllCustomerTypes})
      totalDiscountPrice =  totalDiscountPrice+item?.quantity*Number(priceDetailOfAllCustomerTypes[props?.customerType])
    }
    cartData = data
    setCartData(cartData)
    setTotalDiscountPrice(""+totalDiscountPrice)
   }
  
  return (
    <>
        {loading?
          <div className='flex justify-center'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>:
        <>
            <p className={`font-bold text-black text-base text-${colors?.heading}`}>{t('OrderSummary')}</p>
            <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border border-appThemeColorAboveLight bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded-lg shadow-md p-4 mb-2 `}>
                {totalDiscountPrice && cartData?.map((item, index) => (
                item?.priceDetails && <div key={`_${index}OrderSummary`} className="flex justify-between">
                    <p className={`text-${colors?.heading} text-sm`}>{item.quantity} x {item.name} (₹{item?.priceDetails[props?.customerType]})</p>
                    <p className={`text-${colors?.heading} text-sm`}>
                    {item?.mrp!=item?.priceDetails[props?.customerType] && <span className="line-through">₹{item?.quantity * item?.mrp}</span>}&nbsp;
                    ₹{item?.quantity * item?.priceDetails[props?.customerType]}
                    </p>
                </div>
                ))}
                <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                {cartItems?.billDetails && (
                <>
                    <div className="flex items-center justify-between my-1">
                    <div className="flex items-center">
                        <RiBarChart2Fill className={`text-${colors?.cartIconBadge}`} name="graph-bar" size={12} />
                        <p className={`text-${colors?.heading} text-sm ml-2`}>{t('MRP')}</p>
                    </div>
                    <p className={`text-${colors?.heading} text-sm`}>₹{cartItems?.billDetails?.itemsMrp}</p>
                    </div>
                    <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>
                                {t('ItemsDiscount')} <span className={`text-${colors?.cartIconBadge}`}>({((parseInt(cartItems?.billDetails?.itemsMrp - cartItems?.billDetails?.itemsTotal) / parseInt(cartItems?.billDetails?.itemsMrp)) * 100).toFixed(1)}%)</span>
                            </p>
                        </div>
                        <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(cartItems?.billDetails?.itemSavings)}</p>
                    </div>
                    <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <FaListAlt className={`text-${colors?.cartIconBadge}`} name="list-alt" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>{t('ItemsTotalPrice')}</p>
                        </div>
                        <p className={`text-${colors?.heading} text-sm`}> ₹{cartItems?.billDetails?.itemsTotal}</p>
                    </div>
                    {cartItems?.billDetails?.nestInfo?.applied && <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>
                                {t('NestCash')} 
                            </p>
                        </div>
                        <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(cartItems?.billDetails?.nestInfo?.amount)}</p>
                    </div>}
                    {cartItems?.billDetails?.walletInfo?.applied && <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>
                                {t('Wallet')} 
                            </p>
                        </div>
                        <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(cartItems?.billDetails?.walletInfo?.amount)}</p>
                    </div>}
                    {cartItems?.billDetails?.couponInfo?.applied && <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>
                                {t('Coupon')} 
                            </p>
                        </div>
                        <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(cartItems?.billDetails?.couponInfo?.amount)}</p>
                    </div>}

                    <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                    <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <MdDeliveryDining className={`text-${colors?.cartIconBadge}`} name="delivery-dining" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>{t('DeliveryCharges')}</p>
                        </div>
                        {(cartItems?.billDetails?.chargesWaived == 2 || cartItems?.billDetails?.chargesWaived == 3)?
                        <div className='flex flex-row items-center'>
                            <p className={`text-${colors?.heading} line-through text-sm`}>₹{cartItems?.billDetails?.deliveryCharge}</p>&nbsp;
                            <p className={`text-${colors?.cartIconBadge} text-sm`}>Free</p>
                        </div>
                        :<p className={`text-${colors?.heading} text-sm`}>+ ₹{cartItems?.billDetails?.deliveryCharge}</p>}
                    </div>
                    <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiShoppingBag4Fill className={`text-${colors?.cartIconBadge}`} name="shopping-bag" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>{t('HandlingCharge')}</p>
                        </div>
                        {(cartItems?.billDetails?.chargesWaived == 1 || cartItems?.billDetails?.chargesWaived == 3)?
                        <div className='flex flex-row items-center'>
                            <p className={`text-${colors?.heading} line-through text-sm`}>₹{cartItems?.billDetails?.handlingCharge}</p>&nbsp;
                            <p className={`text-${colors?.cartIconBadge} text-sm`}>Free</p>
                        </div>
                        :<p className={`text-${colors?.heading} text-sm`}>+ ₹{cartItems?.billDetails?.handlingCharge}</p>}
                    </div>
                    <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                    {(cartItems?.billDetails?.totalSaving != cartItems?.billDetails?.itemSavings) && <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>
                                {t('TotalSavings')} 
                            </p>
                        </div>
                        <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(cartItems?.billDetails?.totalSaving)}</p>
                    </div>}
                </>
                )}
                <div className="flex justify-between my-1">
                <p className={`text-${colors?.heading} text-sm`}>{t('GrandTotal')} ({totalCartItems})</p>
                <p className={`text-${colors?.heading} text-sm`}>
                    {cartItems?.billDetails?.itemsMrp !=cartItems?.billDetails?.grandTotal && <span className="line-through">₹{cartItems?.billDetails ? (parseInt(cartItems?.billDetails?.totalSaving)+parseInt(cartItems?.billDetails?.grandTotal)) : totalActualPrice}</span>}
                    <text className='font-bold'> ₹{cartItems?.billDetails ? cartItems?.billDetails?.grandTotal : totalDiscountPrice}</text>
                </p>
                </div>
            </div>
            <p className={`text-center text-xxs sm:text-xxs md:text-xs  lg:text-sm  xl:text-sm my-2 text-${colors?.heading}`}>{t("PrivacyDisclaimer")}</p>
        </>
        }
    </>
  )
}
