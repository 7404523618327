import React, { useContext, useEffect, useState } from 'react'
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';

export default function PrivacyPolicy() {

  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
  var [data,setData] = useState(
    {
        title:'Privacy Policy',
        description:'Native Roots Retail Private Limited ("we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, and disclose information when you use our website, mobile application, or any other services offered by Native Roots Retail Private Limited (collectively, the "Services").',
        details:[
            {
                heading:'1. Information We Collect',
                info:['We may collect the following types of information when you use our Services:','•	Personal Information: This may include your name, email address, phone number, delivery address, payment information, and any other information you provide to us.','•	Usage Information: We collect information about how you interact with our Services, including your IP address, browser type, device identifiers, pages viewed, and other usage data.','•	Location Information: With your consent, we may collect precise location data when you use our mobile application or website.','•	Cookies and Similar Technologies: We use cookies and similar technologies to collect information about your browsing activities and preferences.']
            },
            {
                heading:'2. How We Use Your Information',
                info:['We may use the information we collect for the following purposes:','• To provide and improve our Services.','• To process your orders and payments.','• To communicate with you about your orders, promotions, and other updates.','• To personalize your experience and customize content.','•	To comply with legal obligations or to protect our rights and interests.','• For analytics and research purposes.']
            },
            {
                heading:'3. Information Sharing and Disclosure',
                info:['We may share your information with third parties in the following circumstances:','•	With our service providers, vendors, and other partners who assist us in operating our Services.','•	With third-party delivery partners to fulfill your orders.','•	With your consent or at your direction.','•	To comply with legal obligations or to protect our rights and interests.']
            },
            {
                heading:'4. Data Security',
                info:['We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.']
            },
            {
                heading:'5. Your Choices',
                info:['You may choose not to provide certain information, but this may limit your ability to use certain features of our Services. You can also opt-out of receiving promotional emails and other communications from us.']
            },
            {
                heading:'6. Updates to this Privacy Policy',
                info:['We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new Privacy Policy on this page.']
            },
            {
                heading:'7. Contact Us',
                info:['If you have any questions or concerns about this Privacy Policy, please contact us at [customercare@nativeroots.in].']
            },
        ]
    }
  )

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
        <div className={`pb-1p p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
            <div className="overflow-y-auto p-2.5">
                <div className={`p-4 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                    <h1 className="font-bold text-lg mb-1.5" style={{ color: colors?.heading }}>{data?.title}</h1>
                    <p className="text-sm text-justify" style={{ color: colors?.heading }}>{data?.description}</p>
                </div>
                {
                    data.details.map((item, index) => (
                        <div key={`_${index}PrivacyPolicy`} className={`p-4 mt-2.5 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                            <h2 className="font-bold text-lg" style={{ color: colors?.heading }}>{item?.heading}</h2>
                            {
                                item?.info.map((value, index) => (
                                    <p key={`_${index}PrivacyPolicy1`} className="text-sm mt-1.5 text-left" style={{ color: colors?.heading , textAlign: 'justify'}}>{value}</p>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
            {/* <Footer/> */}
        </div>
    </>
  )
}
