import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
import { QueryOrderTransactionStatus, GetOrderDetailsById } from "../../apollo/queries";
import { gql, useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import { RiBarChart2Fill } from "react-icons/ri";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaListAlt } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { RiShoppingBag4Fill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import CheckOrderStatus from '../../utils/CheckOrderStatus';
import CheckPaymentStatus from '../../utils/CheckPaymentStatus';
import CheckRefundStatus from '../../utils/CheckRefundStatus';
import CheckPaymentMethod from '../../utils/CheckPaymentMethod';
import moment from 'moment';
import UseCookie from '../../utils/cookies/useCookie';
import { CancelOrder,ChangePaymentToCODForOrder  } from "../../apollo/mutations";
import { toast } from 'react-toastify';
import Loader from '../../ui-components/Loader/Loader';


const getOrderDetailsByOrderId = gql`
  ${GetOrderDetailsById}
`;

const CANCELORDER = gql`
  ${CancelOrder}
`;

const UPDATEPAYMENTMETHOD = gql`
  ${ChangePaymentToCODForOrder}
`;


export default function OrderDetails(props) {
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
    var [orderDetailsData, setOrderDetailsData] = useState({});
    const { id } = useParams();
    const { t } = useTranslation()
    const navigateScreenTo = useNavigate();
    const { getCustomCookie, removeCustomCookie, setCustomCookie} = UseCookie();
    var [isLoading, setIsLoading] = useState(false);
    const [cancelOrder] = useMutation(CANCELORDER);
    const [UpdatePaymentMethod] = useMutation(UPDATEPAYMENTMETHOD);
    var [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const client = useApolloClient();
    const { pathname } = useLocation();

    const fetchOrderDetails = async(OrderId) => {
        isLoading = true
        setIsLoading(isLoading)
        let requestBody = {
            "orderId": OrderId
          }
        console.log("🚀 ~ fetchOrderDetails ~ requestBody:", requestBody)
        const { data,loading,error } = await client.query({
            query: getOrderDetailsByOrderId,
            variables:requestBody,
            fetchPolicy: 'network-only',
          });
          window.scrollTo(0, 0);
        if(data?.getOrderDetailsById?.items){
            orderDetailsData = data
            setOrderDetailsData(orderDetailsData)
            selectedPaymentMethod = orderDetailsData?.getOrderDetailsById?.paymentMethod == 0 ? 'cod':'online'
            setSelectedPaymentMethod(selectedPaymentMethod)
        }
        isLoading = false
        setIsLoading(isLoading)
      }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    React.useEffect(() => {
        
        const fetchData = async () => {
          try {
            await removeCustomCookie("checkOrderDetailsStatus")
            await fetchOrderDetails(id)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [id]);

      const formatOrderDate = (utcDate) => {
        const localDate = moment.utc(utcDate).local();
      
        // Get day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd, ...)
        const dayOfMonth = localDate.format('Do');
      
        // Format date as '30th Apr 2024'
        const formattedDate = localDate.format('Do MMM YYYY');
      
        // Format time as '03:00 pm'
        const formattedTime = localDate.format('hh:mm a');
      
        // Combine formatted date and time
        const formattedDateTime = `${formattedDate}, ${formattedTime}`;
      
        //console.log("🚀 ~ formatOrderDate ~ formattedDateTime:", formattedDateTime)
        return formattedDateTime;
      };

      const priceDetailAsObject = (prices) => {
        let data =  prices?.reduce((priceDetailOfAllCustomerTypes, { customerType, sellingPrice })=>{priceDetailOfAllCustomerTypes[customerType] = sellingPrice;return priceDetailOfAllCustomerTypes},{})
        return data
      }    

      const OrderItem = ({item,index}) => {
          let loginInfo = getCustomCookie('loginInfo')
          let customerType = loginInfo?.customerType!=undefined?loginInfo?.customerType:0
          const priceDetailOfAllCustomerTypes = useMemo(() => priceDetailAsObject(item?.prices), [item]);
    
      return <div key={`_${index}OrderSummary`} className="flex justify-between">
        <p className={`text-${colors?.heading} text-sm`}>{item.quantity} x {item.name} (₹{priceDetailOfAllCustomerTypes[customerType]})</p>
        <p className={`text-${colors?.heading} text-sm`}>
        <span className="line-through">₹{item.quantity * item.mrp}</span> ₹{item.quantity * priceDetailOfAllCustomerTypes[customerType]}
        </p>
      </div>
    }

    const cancelOrderClick = async()=>{
        let requestBody = {
          orderId:orderDetailsData?.getOrderDetailsById?._id
        }
        isLoading = true
        setIsLoading(isLoading)
        try{
          let data = await cancelOrder({ variables:requestBody})
          console.log("🚀 ~ cancelOrderClick ~ data:", data)
          if(data?.data?.cancelOrder){ 
            toast.success(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} Order Cancelled`)
            // fetchOrderDetails(orderDetailsData?.getOrderDetailsById?._id)
            fetchOrderDetails(orderDetailsData?.getOrderDetailsById?._id)
          }
          else{
            toast.error(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} Order Failed to Cancel`)
            }
        }catch(e){
          isLoading = false
          setIsLoading(isLoading)
          toast.error(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} Order Failed to Cancel`)
        }
      }
    
    
      const paymentTryAgain = async ( )=>{
        const params = new URLSearchParams({
            orderId: orderDetailsData?.getOrderDetailsById?._id,
            amount: orderDetailsData?.getOrderDetailsById?.billDetails?.grandTotal,
          });
          // await setCustomCookie('checkOrderDetailsStatus', JSON.stringify({orderId: orderDetailsData?.getOrderDetailsById?._id,}))
          window.location.href  = `${process.env.REACT_APP_SERVER_URL}/phonepe/payment?${params.toString()}`
      }
    
    
      const changePaymentMethodChange = async(event) => {
        selectedPaymentMethod = event.target.value
        setSelectedPaymentMethod(selectedPaymentMethod)
      }
    
      const updatePlaceOrder = async() => {
        try{
          isLoading = true
          setIsLoading(isLoading)
            let requestBody = {orderId:orderDetailsData?.getOrderDetailsById?._id,}
            let data = await UpdatePaymentMethod({ variables:requestBody})
            console.log("🚀 ~ handlePaymentMethodChange ~ data:", data)
            toast.success(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} order successfully updated to cash on delivery`)
            fetchOrderDetails(orderDetailsData?.getOrderDetailsById?._id)
        }
        catch(error){
          isLoading = false
          setIsLoading(isLoading)
          toast.error(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} order failed to update cash on delivery`)
        }
      };

    const downloadPDF = async () => {
        const requestBody = {
          invoiceId: orderDetailsData?.getOrderDetailsById?.invoiceId || "KA24/06-IN00001"
        };
    
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/invoice/download`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
    
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          // const url = window.URL.createObjectURL(new Blob([response.data]));
    
          //download pdf
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'invoice.pdf'); // or extract the name from response headers if available
          document.body.appendChild(link);
          link.click();
    
          //open pdf in new browser tab
          // window.open(url, '_blank');
          // window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading the PDF:', error);
        }
      }

      return (
        <>
        {isLoading ?
            <Loader loading={isLoading} />
            // <div className='flex justify-center'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>
            :<>
              <div className='flex flex-col items-center justify-center min-h-screen'>
                  {orderDetailsData?.getOrderDetailsById?<div className={`pb-1p p-0.5p mb-5 shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
                      <div className='flex flex-row justify-between m-2'>
                          <div className={`flex self-start align-left items-left text-md sm:text-md md:text-md lg:text-xl xl:text-xl gap-2 text-${colors?.heading} font-bold`}>
                          <FaArrowLeftLong className={`hover:text-appThemeColorVeryDark cursor-pointer text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl my-2 transition-transform ease-in-out cursor-pointer duration-200 active:scale-50 text-${colors?.heading}`} onClick={()=>navigateScreenTo("/")} />
                          <div className={`text-md my-1 sm:text-md md:text-lg lg:text-lg xl:text-xl text-${colors?.heading}`}>Order ID - {`#${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)}`}</div>
                          </div>
                              {orderDetailsData?.getOrderDetailsById?.invoiceId && <button className="flex self-end align-right items-right bg-appThemeColorVeryDark text-white flex justify-between items-center rounded p-1p" onClick={downloadPDF}>
                              <div className='flex flex-wrap items-center justify-center w-full' >
                                  <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md mx-1">Download Invoice</span>
                              </div>
                              </button>}
                      </div>
                      {/* <h1 className="font-bold text-lg mb-1.5" style={{ color: colors?.heading }}></h1> */}
                      {/* <div>
                          <div className={` border-appThemeColorAboveLight border-2 shadow relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded`} 
                          style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}}>

                          <div className='w-full mx-2'>
                          <div className="flex justify-between "> 
                              <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Ordered " +  orderDetailsData?.getOrderDetailsById?.items?.length + " items" +' ('+ CheckOrderStatus(orderDetailsData?.getOrderDetailsById?.orderState?.status)+")"}</span>
                              <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md mr-1 font-bold text-appThemeColorVeryDark`}>{`#${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)}`}</span>
                          </div>
                          <div className="text-left "> 
                              <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order ID : {transactionData?.orderId ? `#${transactionData?.orderId?.slice(-5)}` : " - "}</div>
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Status : {transactionData?.orderStatus ? transactionData?.orderStatus : " - "}</div>
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Status : {transactionData?.paymentStatus ? transactionData?.paymentStatus : " - "}</div>
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Transaction Amount : {transactionData?.amount ? transactionData?.amount : " - "}</div>
                          </div>
                          </div>
                      </div>
                      </div> */}
                      <div>
                      <div className={` border-appThemeColorAboveLight border-2 shadow relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded`} 
                          style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}}>

                          <div className='w-full mx-2'>
                          <div className="flex justify-between "> 
                              <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Ordered " +  orderDetailsData?.getOrderDetailsById?.items?.length + (orderDetailsData?.getOrderDetailsById?.items?.length>1?' items':' item') +' ('+ CheckOrderStatus(orderDetailsData?.getOrderDetailsById?.orderState?.status)+")"}</span>
                              {/* <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-appThemeColorVeryDark`}>₹{item?.orderAmount}</span> */}
                              <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md mr-1 font-bold text-appThemeColorVeryDark`}>{`#${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)}`}</span>
                          </div>
                          <div className="text-left "> 
                              <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Amount : {"₹" + orderDetailsData?.getOrderDetailsById?.orderAmount}</div>
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Paid Amount : {"₹" + orderDetailsData?.getOrderDetailsById?.paidAmount}</div>
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Method : {CheckPaymentMethod(orderDetailsData?.getOrderDetailsById?.paymentMethod)}</div>
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Status : { CheckPaymentStatus(orderDetailsData?.getOrderDetailsById?.paymentStatus)}</div>
                              {orderDetailsData?.getOrderDetailsById?.refundStatus!=0 && <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Refund Status : { CheckRefundStatus(orderDetailsData?.getOrderDetailsById?.refundStatus)}</div>}
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Date : {formatOrderDate(orderDetailsData?.getOrderDetailsById?.orderDate)}</div>
                              <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Delivery Date : {orderDetailsData?.getOrderDetailsById?.deliveryDate}</div>
                          </div>
                          </div>
                      </div>
                      {/* Offers Applied */}
                      {(orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.applied || orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.applied || orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.applied ) && <>
                        <div className="flex flex-col gap-1 mb-3"> 
                          <span className={`text-left text-sm md:text-md font-bold text-${colors?.heading}`}>{t('OffersApplied')}</span>
                          {orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-2 relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                            <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.message}</span>
                          </div>}
                          {orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-2 relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                            <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.message}</span>
                          </div>}
                          {orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-2 relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left`} >
                            <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.message}</span>
                          </div>}
                        </div>
                      </>}
                      {/* Address */}
                      {orderDetailsData?.getOrderDetailsById?.deliveryAddress?.deliveryAddress && 
                          <>
                          <div className="text-left "> 
                                  <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>Address</span>
                          </div>
                          <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`relative gap-2 flex flex-row items-center mb-3 p-2 border-appThemeColorAboveLight border-2 shadow bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                              <FaLocationDot className="text-green-600 text-lg sm:text-xl md:text-xl lg:text-xxl xl:text-xxl" />
                              <span className={`text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.deliveryAddress?.deliveryAddress}</span>
                          </div>
                          </>
                      }
                      {orderDetailsData?.getOrderDetailsById?.paymentStatus === 0 && orderDetailsData?.getOrderDetailsById?.orderState?.status < 7 && orderDetailsData?.getOrderDetailsById?.paymentMethod !== 0 && <>
                          <div className="text-left "> 
                              <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{t('ChnagePaymentMethod')}</span>
                          </div>
                          <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`relative border-appThemeColorAboveLight border-2 shadow flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left`} >
                          <div className="flex flex-col space-y-4">
                              <label className="flex items-center">
                              <input
                                  type="radio"
                                  value="cod"
                                  checked={selectedPaymentMethod == 'cod'}
                                  onChange={changePaymentMethodChange}
                                  className="form-radio cursor-pointer h-4 w-4 text-green-600 checked:bg-green-600 focus:bg-green-600 focus:ring-0 transition duration-100 ease-in-out"
                                  />
                              <span className="ml-2 text-gray-700">{t('cod')}</span>
                              </label>
                              <label className="flex items-center">
                              <input
                                  type="radio"
                                  value="online"
                                  onChange={changePaymentMethodChange}
                                  checked={selectedPaymentMethod == 'online'}
                                  className="form-radio cursor-pointer h-4 w-4 text-green-600 checked:bg-green-600 focus:bg-green-600 focus:ring-0 transition duration-100 ease-in-out"
                              />
                              <span className="ml-2 text-gray-700">{t('online')}</span>
                              </label>
                          </div>          
                          </div>
                      </>}
                      <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-appThemeColorAboveLight border-2 shadow bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded-lg shadow-md p-4 my-2 `}>
                          {orderDetailsData?.getOrderDetailsById?.items?.map((item, index) => (
                              <OrderItem item={item} index={index} />
                          ))}
                          <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                          {orderDetailsData?.getOrderDetailsById?.billDetails && (
                          <>
                              <div className="flex items-center justify-between my-1">
                              <div className="flex items-center">
                                  <RiBarChart2Fill className={`text-${colors?.cartIconBadge}`} name="graph-bar" size={12} />
                                  <p className={`text-${colors?.heading} text-sm ml-2`}>{t('MRP')}</p>
                              </div>
                              <p className={`text-${colors?.heading} text-sm`}>₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp}</p>
                              </div>
                              <div className="flex items-center justify-between my-1">
                              <div className="flex items-center">
                                  <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                                  <p className={`text-${colors?.heading} text-sm ml-2`}>
                                  {t('Discount')} <span className={`text-${colors?.cartIconBadge}`}>({((parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp - orderDetailsData?.getOrderDetailsById?.billDetails?.itemsTotal) / parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp)) * 100).toFixed(1)}%)</span>
                                  </p>
                              </div>
                              <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.totalSaving)}</p>
                              </div>
                              <div className="flex items-center justify-between my-1">
                                <div className="flex items-center">
                                    <FaListAlt className={`text-${colors?.cartIconBadge}`} name="list-alt" size={12} />
                                    <p className={`text-${colors?.heading} text-sm ml-2`}>{t('ItemsTotalPrice')}</p>
                                </div>
                                <p className={`text-${colors?.heading} text-sm`}> ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsTotal}</p>
                              </div>
                              {orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.applied && <div className="flex items-center justify-between my-1">
                                  <div className="flex items-center">
                                      <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                                      <p className={`text-${colors?.heading} text-sm ml-2`}>
                                          {t('NestCash')} 
                                      </p>
                                  </div>
                                  <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.amount)}</p>
                              </div>}
                              {orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.applied && <div className="flex items-center justify-between my-1">
                                  <div className="flex items-center">
                                      <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                                      <p className={`text-${colors?.heading} text-sm ml-2`}>
                                          {t('Wallet')} 
                                      </p>
                                  </div>
                                  <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.amount)}</p>
                              </div>}
                              {orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.applied && <div className="flex items-center justify-between my-1">
                                  <div className="flex items-center">
                                      <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                                      <p className={`text-${colors?.heading} text-sm ml-2`}>
                                          {t('Coupon')} 
                                      </p>
                                  </div>
                                  <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.amount)}</p>
                              </div>}
                              <div className="flex items-center justify-between my-1">
                                  <div className="flex items-center">
                                      <MdDeliveryDining className={`text-${colors?.cartIconBadge}`} name="delivery-dining" size={12} />
                                      <p className={`text-${colors?.heading} text-sm ml-2`}>{t('DeliveryCharges')}</p>
                                  </div>
                                  {(orderDetailsData?.getOrderDetailsById?.billDetails?.chargesWaived == 2 || orderDetailsData?.getOrderDetailsById?.billDetails?.chargesWaived == 3)?
                                      <div className='flex flex-row items-center'>
                                          <p className={`text-${colors?.heading} line-through text-sm`}>₹{orderDetailsData?.getOrderDetailsById?.billDetails?.deliveryCharge}</p>&nbsp;
                                          <p className={`text-${colors?.cartIconBadge} text-sm`}>Free</p>
                                      </div>
                                      :<p className={`text-${colors?.heading} text-sm`}>+ ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.deliveryCharge}</p>}
                              </div>
                              <div className="flex items-center justify-between my-1">
                                  <div className="flex items-center">
                                      <RiShoppingBag4Fill className={`text-${colors?.cartIconBadge}`} name="shopping-bag" size={12} />
                                      <p className={`text-${colors?.heading} text-sm ml-2`}>{t('HandlingCharge')}</p>
                                  </div>
                                  {(orderDetailsData?.getOrderDetailsById?.billDetails?.chargesWaived == 1 || orderDetailsData?.getOrderDetailsById?.billDetails?.chargesWaived == 3)?
                                      <div className='flex flex-row items-center'>
                                          <p className={`text-${colors?.heading} line-through text-sm`}>₹{orderDetailsData?.getOrderDetailsById?.billDetails?.handlingCharge}</p>&nbsp;
                                          <p className={`text-${colors?.cartIconBadge} text-sm`}>Free</p>
                                      </div>
                                      :<p className={`text-${colors?.heading} text-sm`}>+ ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.handlingCharge}</p>}
                              </div>
                              <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                          </>
                          )}
                          <div className="flex justify-between my-1">
                          <p className={`text-${colors?.heading} text-sm`}>{t('GrandTotal')} ({orderDetailsData?.getOrderDetailsById?.items?.length})</p>
                          <p className={`text-${colors?.heading} text-sm`}>
                              <span className="line-through">₹{(parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.totalSaving)+parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.grandTotal))}</span>
                              <span className='font-bold'> ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.grandTotal }</span>
                          </p>
                          </div>
                      </div>
                  </div>
                  {orderDetailsData?.getOrderDetailsById?.orderState?.status<7 && 
                      <>
                      <div className="gap-5 flex flex-row items-center justify-center">
                          <button className="w-full bg-appThemeColorVeryDark text-white flex justify-between items-center rounded p-2p" onClick={cancelOrderClick}>
                          <div className='flex flex-wrap items-center justify-center w-full' >
                              <span className="font-bold text-sm sm:text-sm md:text-md lg:text-md xl:text-md mx-1">{t('CancelOrder')}</span>
                          </div>
                          </button>
                          {orderDetailsData?.getOrderDetailsById?.paymentStatus === 0 && orderDetailsData?.getOrderDetailsById?.orderState?.status < 7 && orderDetailsData?.getOrderDetailsById?.paymentMethod !== 0 && 
                          <button className="w-full bg-appThemeColorVeryDark text-white flex justify-between items-center rounded p-2p" onClick={selectedPaymentMethod == 'online'?paymentTryAgain:updatePlaceOrder}>
                          <div className='flex flex-wrap items-center justify-center w-full' >
                              <span className="font-bold text-sm sm:text-sm md:text-md lg:text-md xl:text-md mx-1">{selectedPaymentMethod == 'online'?t('RetryPayment'):t('UpdateOrder')}</span>
                          </div>
                          </button>}
                      </div>
                      </>
                      }
                  </div>:
                  <>
                    <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>No Data Available</div>
                  </>}
              </div>
               
            </>}
        </>
      );
}