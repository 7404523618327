import React,{useContext, useMemo} from 'react'
import { useNavigate } from 'react-router-dom';
import { FaCrown } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
// import { LoginInfoContext } from '../../context/loginInfo';
import UseCookie from '../../utils/cookies/useCookie';
import { useTranslation } from 'react-i18next';

const GroceryItemCard = React.memo(({item,index, textText, openPremuimBanner,callBackAction,closeBottomSheetModal,cartItems, addToCart,removeFromCart,navigation,columns, customerType, generalCustomerType, isLocationServicable}) => {
// console.log("🚀 ~ GroceryItemCard ~ item:", item?.outOfStock)
  const {t} = useTranslation()
  const navigateScreenTo = useNavigate();
  const { getCustomCookie } = UseCookie();
  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   

  const onPressEvent = ()=>{
    if(closeBottomSheetModal){
      closeBottomSheetModal()
    }
    if(callBackAction){
      callBackAction(item)
    }
    navigateScreenTo(item?.mrp?`/productDetails/${item?.itemId || item?.productId}`:`/SubCategories/${item?.itemId || item?.productId}`,{state:item})
  }
    
  // let image ='https://cdn.nativenest.in/pro/logo/Native-Nest-Trans-Logo.png'
  let image = process.env.REACT_APP_PLACEHOLDER_IMAGE

  const priceDetailAsObject = (prices) => {
    // console.log("🚀 ~ priceDetailAsObject ~ prices:", prices)
    let data =  prices?.reduce((priceDetailOfAllCustomerTypes, { customerType, sellingPrice })=>{priceDetailOfAllCustomerTypes[customerType] = sellingPrice;return priceDetailOfAllCustomerTypes},{})
    return data
  }

  //Only works when customer is a general or With skip login
  const getPremuimPrice = (AllCustomerTypes, generalCustomerType) => {
    for (let customerType in AllCustomerTypes) {
      if (customerType != generalCustomerType) {
        return AllCustomerTypes[customerType];
      }
    }
    return null; // or some other default value or action if no other key exists
  };

  const addToCartAction = async(e,item)=>{
    e.stopPropagation(); 
    let loginInfo = await getCustomCookie('loginInfo')
    let locationInfo = await getCustomCookie('locationInfo')
    addToCart({...item,quantity:0},NaN,cartItems, isLocationServicable,loginInfo,locationInfo)
    }
    
    const handleRemoveFromCart = async(e,item)=>{
      e.stopPropagation(); 
      let loginInfo = await getCustomCookie('loginInfo')
      let locationInfo = await getCustomCookie('locationInfo')
      removeFromCart({...item,quantity:cartItems['cartobj'][item?.itemId ?? item?.productId]?.quantity},NaN,cartItems, isLocationServicable,loginInfo,locationInfo)
      }
      
      const handleAddToCart = async(e,item)=>{
        e.stopPropagation(); 
        let loginInfo = await getCustomCookie('loginInfo')
        let locationInfo = await getCustomCookie('locationInfo')
    addToCart({...item,quantity:cartItems['cartobj'][item?.itemId ?? item?.productId]?.quantity},NaN,cartItems, isLocationServicable,loginInfo, locationInfo)
  }

  // Use useMemo to memoize the result of the expensive calculation
  const priceDetailOfAllCustomerTypes = useMemo(() => priceDetailAsObject(item?.prices), [item]);


  let cardStyling = item?.prices?.length >0 ? `relative bg-${colors?.bottomTabBg} dark:bg-${colors?.bottomTabBg} p-2 w-24 sm:w-24 md:w-35 lg:w-40 xl:w-40 ${(priceDetailOfAllCustomerTypes && ((typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[customerType]) != item?.mrp)?'h-44 sm:h-44 md:h-44 lg:h-48 xl:h-60':'h-44 sm:h-44 md:h-50 lg:h-60 xl:h-60'} max-h-xs flex flex-col text-center justify-between overflow-hidden rounded shadow-md hover:shadow-xl  transition-shadow duration-300 ease-in-out cursor-pointer`:
  `relative bg-${colors?.bottomTabBg} dark:bg-${colors?.bottomTabBg} p-2 w-24 sm:w-24 md:w-35 lg:w-40 xl:w-40 h-22 sm:h-22 md:h-25 lg:h-30 xl:h-30 max-h-xs flex flex-col text-center justify-between overflow-hidden rounded shadow-md hover:shadow-xl  transition-shadow duration-300 ease-in-out cursor-pointer`;
  return (
    <>
          {(item?.offerEnabled && item?.offerImage)?
            <div onClick={onPressEvent} style={{backgroundColor:Colors.colorTheme[colors?.bottomTabBg],padding:0}}  className={cardStyling} >
              <div className="relative flex flex-col h-full w-full overflow-hidden">
                <img src={item?.offerImage ? item?.offerImage : image} className="w-full h-full object-cover rounded transform transition-transform duration-500 hover:scale-110" alt="Item" />
                {item?.outOfStock && (
                  <div className="absolute bottom-0 w-full flex justify-center">
                    <span className="w-full bg-white bg-opacity-40 backdrop-blur-sm text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs lg:font-bold xl:font-bold text-red-500 text-center p-1">
                      {t('OutofStock')}
                    </span>
                  </div>
                )}
              </div>
              {item?.mrp && priceDetailOfAllCustomerTypes && (priceDetailOfAllCustomerTypes[customerType] != item?.mrp) && (
                  <div ><div className="absolute top-0 left-0  items-center justify-center bg-appThemeColor rounded-sm px-1 sm:px-1 md:px-1 lg:px-2">
                    <span className={`text-${colors?.textWhite ?? 'white'} text-xxs sm:text-xxs lg:text-xs md:text-sm xl:text-sm font-bold`}>
                      {(((item?.mrp - priceDetailOfAllCustomerTypes[customerType]) / item?.mrp) * 100).toFixed(0)} % off
                    </span>
                  </div></div>
                )}
            </div>:
            <div onClick={onPressEvent} style={{backgroundColor:Colors.colorTheme[colors?.bottomTabBg]}}  className={cardStyling} >
            {/* {(<div className="relative flex flex-col h-14 sm:h-14 md:h-18 lg:h-24 w-full overflow-hidden">
                <img src={item?.image ? item?.image : image} className="w-full h-full object-contain" alt="Item" />
                <div className="absolute inset-0 bg-gradient-to-r from-transparent to-black opacity-50"> 
                  <text className="text-xxxs sm:text-xxxs md:text-xxxs lg:text-xs xl:text-xs lg:font-bold xl:font-bold text-red-500 flex items-center justify-center h-full"> 
                    {t('OutofStock')}
                  </text>
                </div>
              </div>
              )}  */}
              <div className="relative flex flex-col h-14 sm:h-14 md:h-18 lg:h-24 xl:h-32 w-full overflow-hidden">
                <img src={item?.image ? item?.image : image} className="w-full h-full object-contain" alt="Item" />
                {item?.outOfStock && <div className="absolute bottom-0 w-full flex justify-center">
                  <span className="w-full bg-white bg-opacity-40 backdrop-blur-sm text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs lg:font-bold xl:font-bold text-red-500 text-center p-1">
                    {t('OutofStock')}
                  </span>
                </div>}
              </div>

              {item?.name && <div className={`text-left flex items-center font-bold ${item?.mrp?'line-clamp-1':'line-clamp-2'}`} style={{justifyContent:item?.mrp ?'left':'center'}} onClick={callBackAction}><h2 className={`line-clamp-1 text-xxs sm:text-xxs md:text-xs  lg:text-xs  xl:text-sm text-${colors?.heading}`}>{item?.name} </h2></div>}
              {/* {item?.description && <div className='text-left flex justify-left items-center' style={{justifyContent:item?.mrp ?'left':'center'}} onClick={callBackAction}><text className={`line-clamp-1 text-xxs sm:text-xxs md:text-xs  lg:text-xxs  xl:text-sm  text-${colors?.heading} text-xs leading-tight`}>{item?.description}</text></div>} */}
              
              {(typeof generalCustomerType != 'number' && priceDetailOfAllCustomerTypes && getPremuimPrice(priceDetailOfAllCustomerTypes,customerType)!=0)?(
                  <button onClick={(e) =>{e.stopPropagation();openPremuimBanner(true)}} className="bg-gradient-to-r from-yellow-600 via-yellow-400 to-yellow-600 text-yellow-900 rounded p-0.5p sm:p-0.5p md:p-0.5p lg:p-1 xl:p-1 mt-1p hover:opacity-90 focus:outline-none transition duration-200 transform active:scale-90">
                    <div className="flex justify-around items-center">
                      <FaCrown className="text-xxs sm:text-xxs md:text-xxs  lg:text-sm  xl:text-sm text-yellow-900" />
                      <text className="text-xxxs sm:text-xxxs md:text-xxxs  lg:text-xs  xl:text-xs  lg:font-bold xl:font-bold ">Get for ₹{getPremuimPrice(priceDetailOfAllCustomerTypes,customerType)}</text>
                      <MdKeyboardArrowDown className="text-sm sm:text-sm md:text-sm  lg:text-lg  xl:text-lg  text-yellow-900 pt-0.5" />
                    </div>
                  </button>
                ):<div className='flex mt-1p'></div>}
                {item?.mrp && priceDetailOfAllCustomerTypes && ((typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[customerType]) != item?.mrp && (
                  <div ><div className="absolute top-0 left-0  items-center justify-center bg-appThemeColor rounded-sm px-1 sm:px-1 md:px-1 lg:px-2">
                    <span className={`text-${colors?.textWhite ?? 'white'} text-xxs sm:text-xxs lg:text-xs md:text-sm xl:text-sm font-bold`}>
                      {(((item?.mrp - ((typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[customerType])) / item?.mrp) * 100).toFixed(0)} % off
                    </span>
                  </div></div>
                )}
                {priceDetailOfAllCustomerTypes && ((typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[customerType]) != item?.mrp && (
                    <FaCrown className="absolute top-0 right-0 text-yellow-600 text-xs sm:text-sm md:text-md  lg:text-lg  xl:text-md mr-1 mt-1"  />
                  // <div className="absolute top-0 right-0 bg-gradient-to-r from-yellow-600 via-yellow-400 to-yellow-600 text-yellow-900 rounded p-0.5p sm:p-0.5p md:p-0.5p lg:p-1 xl:p-1 hover:opacity-90 focus:outline-none">
                  //   <FaCrown className="absolute top-0 right-0 bg-gradient-to-r from-yellow-600 via-yellow-400 to-yellow-600 text-yellow-900 text-xxs sm:text-xxs md:text-xxs  lg:text-sm  xl:text-sm text-yellow-900" />
                  // </div>
                )}
              {priceDetailOfAllCustomerTypes && <div className="w-full flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row justify-between ">
                  <div onClick={callBackAction} className="text-left mb-1 sm:mb-0 w-full lg:w-2/3 items-center justify-start flex"> {/* Add sm:mb-0 to make sure it aligns correctly on small screens */}
                    {((typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[customerType])&&<text className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>₹{(typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[customerType]}</text>}
                    {typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] != 0 && priceDetailOfAllCustomerTypes[generalCustomerType] != item?.mrp && priceDetailOfAllCustomerTypes[generalCustomerType] && <span className={`text-appThemeColorDark text-sm mr-1 mt-[1px] line-through text-${colors?.heading}`}>₹{priceDetailOfAllCustomerTypes[generalCustomerType]}</span>}
                    {((typeof generalCustomerType == 'number' && priceDetailOfAllCustomerTypes[customerType] == 0) ?priceDetailOfAllCustomerTypes[generalCustomerType]:priceDetailOfAllCustomerTypes[customerType]) != item?.mrp && <text className={`text-xs line-through mt-[1px] text-${colors?.heading}`}>₹{item?.mrp}</text>}
                  </div>
                  {!cartItems?.cartobj || !cartItems?.cartobj?.[item?.itemId ?? item?.productId]?.quantity ? (
                    <button
                      onClick={(e) =>{addToCartAction(e,item)}}
                      disabled={item?.outOfStock}
                      className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md w-full lg:w-1/3 bg-appThemeColorVeryDark text-white text-center flex justify-center items-center rounded mt-1 sm:mt-0 ${(item?.outOfStock)?'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-200 transform active:scale-90'}`}
                    >
                      Add
                    </button>
                  ) : (
                    <div className="flex flex-row w-full lg:w-16 bg-appThemeColorDark text-white text-center justify-around items-center align-center rounded mt-1 sm:mt-0">
                      <button className="pl-1 text-white text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold opacity-100 cursor-pointer transition duration-200 transform active:scale-50" onClick={(e) =>{handleRemoveFromCart(e,item)}}>-</button>
                      <span className="text-white text-sm sm:text-sm md:text-md lg:text-md xl:text-md  font-bold">{cartItems['cartobj'][item?.itemId ?? item?.productId]?.quantity}</span>
                      <button className={`pr-1 text-white text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold ${(item?.outOfStock)?'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-200 transform active:scale-50'}`} onClick={(e) =>{handleAddToCart(e,item)}}>+</button>
                    </div>
                  )}
                </div>}
        </div>}
    </>
  )
})

export default GroceryItemCard