import React,{useContext,useEffect,useState} from 'react'
import { CartContext } from '../../context/Cart'
import GroceryItemCard from '../../ui-components/Cards/GroceryItemCard';
import { useLocation, useNavigate, } from "react-router-dom";
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import { useMutation, gql, useQuery, useLazyQuery } from "@apollo/client";
import { QueryCategories } from "../../apollo/queries";
import { LocationContext } from '../../context/Location';
import Footer from '../Footer/Footer';

const QUERYCATEGORIES = gql`
  ${QueryCategories}
`;


export default function Categories() {
    const { cartItems, addToCart,removeFromCart } = useContext(CartContext)
    const { location } = useContext(LocationContext)
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
    const navigateScreenTo = useNavigate();
    var [refreshing, setRefreshing] = useState(false);
    var [isScrolling, setIsScrolling] = useState(false);
    var [isLoading, setIsLoading] = useState(false);
    var [cartData, setCartData] = useState([]);
    var [totalCartItems, setTotalCartItems] = useState([]);
    var [totalActualPrice, setTotalActualPrice] = useState([]);
    var [pageNumber, setPageNumber] = useState(0);
    var [totalRecords, setTotalRecords] = useState(0);
    var [totalPages, setTotalPages] = useState(0);
    var [selectedCategory, setSelectedCategory] = useState(false);
    var [loginInfo,setLoginInfo] = useState()

    const { pathname } = useLocation();

    useEffect(() => {
      console.log("categories-_____________________")
      refetch({
        variables: {
          "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
          "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
          "rows": 100,
          "columns": 3,
        }
      })
      window.scrollTo(0, 0);
    }, [pathname]);


    const [refetch, { data, loading, error, }] = useLazyQuery(QUERYCATEGORIES, {
      fetchPolicy: 'no-cache',
      onCompleted: (catalogProducts) => {},
    });


  return (
    <>
      <div className={`pb-1p p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
        {(loading && !data)?
        <div className='flex justify-center min-h-screen'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>:
        <div className="flex flex-wrap overflow-y-scroll hide-scroll-bar max-h-screen">
            {data?.queryCategories.items?.map((item)=>(<div className='bg-white m-2 rounded'><GroceryItemCard item={item} /></div>))}
        </div>
        }
        <div className='mt-5'>
          <Footer />
        </div>
      </div>
    </>
  )
}



