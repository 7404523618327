import React, { useContext, useEffect, useState } from 'react'
import {QueryNestTransactions} from "../../apollo/queries";
import { gql, useApolloClient } from '@apollo/client';
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import Lottie from 'lottie-react';
import { FiGift } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";
import moment from 'moment';

const getNestCashTransactions = gql`${QueryNestTransactions}`

export default function NestCash(props) {

    const client = useApolloClient();
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   

    var [isLoading, setIsLoading] = useState(false);
    var [nestCashData, setNestCashData] = useState(false);


    useEffect(() => {
        if(props?.isChecked){
          initialDataload()
        }
      },[props?.isChecked])

      const initialDataload = async()=>{
        window.scrollTo(0, 0);
        isLoading = true
        setIsLoading(isLoading)
        let requestBody = {  
            "skip": 0,
            "limit": 10
          }
        try{
            const { data: nestCashTransactionData } = await client.query({
                query: getNestCashTransactions,
                variables: requestBody,
                fetchPolicy: "network-only",
            });
            console.log("🚀 ~ initialDataload ~ nestCashTransactionData:", nestCashTransactionData?.queryNestTransactions?.balance)
            nestCashData = nestCashTransactionData?.queryNestTransactions
            setNestCashData(nestCashData)
            console.log("🚀 ~ initialDataload ~ nestCashData:", nestCashData)
            isLoading = false
            setIsLoading(isLoading)
        }
        catch(error){
            isLoading = false
            setIsLoading(isLoading)
        }
      }

      const formatOrderDate = (utcDate) => {
        const localDate = moment.utc(utcDate).local();
      
        // Get day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd, ...)
        const dayOfMonth = localDate.format('Do');
      
        // Format date as '30th Apr 2024'
        const formattedDate = localDate.format('Do MMM YYYY');
      
        // Format time as '03:00 pm'
        const formattedTime = localDate.format('hh:mm a');
      
        // Combine formatted date and time
        const formattedDateTime = `${formattedDate} at ${formattedTime}`;
      
        //console.log("🚀 ~ formatOrderDate ~ formattedDateTime:", formattedDateTime)
        return formattedDateTime;
      };

  return (
    <>
        <div className='mt-2p pb-2 overflow-y-scroll hide-scroll-bar max-h-[90vh]'>
            {isLoading ?
                <div className='flex justify-center'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>:
                <div className='flex flex-col'>
                    <div className={` shadow relative flex flex-row items-center justify-between mb-3 px-4 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor}  rounded-xl`} 
                        style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} >
                            <div className='flex flex-col gap-2'>
                                <span className={`text-lg sm:text-lg md:text-2xl lg:text-2xl xl:text-3xl font-bold text-${colors?.heading}`}>₹{nestCashData?.balance}</span>
                                <span className={`text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg font-normal text-appThemeColorVeryDark`}>Available Balance</span>
                            </div>
                            <Lottie className="w-32" animationData={require('../../assets/NestCash.json')} />
                    </div>
                    <span className={`p-1 text-sm md:text-md font-semibold text-black`}>Recent Activity</span>
                    <div className='flex flex-col gap-2'>
                    {
                        nestCashData?.transactions?.length>0?
                        <>
                            {
                                nestCashData?.transactions?.map(data=>(
                                    <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`p-4 rounded-lg flex flex-row gap-2 items-center w-full bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor}`}>
                                        {data?.expired?
                                        <MdErrorOutline style={{color:colors?.fail}} size={32} />:
                                        <FiGift className={`text-${colors?.cartIconBadge}`} size={32} />}
                                        <div className='flex flex-row justify-between items-center w-full'>
                                            <div className='flex flex-col items-start gap-1'>
                                                <span className={`font-semibold text-sm md:text-md text-black`}>{data?.reason}</span>
                                                <span className={`font-normal text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs text-textlightGrey`}>{formatOrderDate(data?.createdAt)}</span>
                                            </div>
                                            <div className='flex flex-col gap-1 items-end justify-end'>
                                                <span className={`font-semibold text-sm sm:text-sm md:text-md ${data?.expired?'text-black':'text-appThemeColorVeryDark'}`}>{data?.expired?`₹${data?.amount}`:`+₹${data?.amount}`}</span>
                                                <span className={`font-normal text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs text-textlightGrey`}>{formatOrderDate(data?.expiry).split("at")[0]}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </>:
                        <div className='h-[50vh] flex flex-col items-center justify-center text-center'>
                            <span className={`text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg font-normal text-black`}>No Transactions</span>  
                        </div>
                    }
                    </div>
                </div>
            }
        </div>
    </>
  )
}
