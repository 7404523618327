import React, { useContext, useState } from 'react'
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import Footer from '../Footer/Footer';
import { useLocation } from 'react-router-dom';

export default function TermsAndConditions() {

  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
  //console.log("🚀 ~ PrivacyPolicy ~ systemThemeMode:", systemThemeMode)
  //console.log("🚀 ~ PrivacyPolicy ~ colors:", colors)
  var [data,setData] = useState(
    {
        title:'Terms and Conditions',
        description:'These Terms and Conditions govern your use of the services provided by Native Roots Retail Private Limited ("Native Roots," "we," "us," or "our") through our website, mobile application, or any other platform (collectively, the "Services"). By using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Services.',
        details:[
            {
                heading:'1. Use of Services',
                info:['a. You must be at least 18 years old to use our Services.', 'b. You agree to provide accurate and complete information when placing orders through our Services.', 'c. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.']
            },
            {
                heading:'2. Ordering and Delivery',
                info:['a. By placing an order through our Services, you agree to pay the specified price for the products and any applicable taxes and delivery fees.', 'b. We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability or errors in pricing.' ,'c. Delivery times provided are estimates only and may vary depending on factors such as traffic and weather conditions.']
            },
            {
                heading:'3. Payment',
                info:['a. Payment for orders placed through our Services must be made using the payment methods accepted by Native Roots.' ,'b. All payments are processed securely, and your payment information will be handled in accordance with our Privacy Policy.']
            },
            {
                heading:'4. User Conduct',
                info:['a. You agree not to use our Services for any unlawful or prohibited purpose.', 'b. You agree not to interfere with or disrupt the operation of our Services or the networks connected to them.']
            },
            {
                heading:'5. Intellectual Property',
                info:['a. All content included on our Services, such as text, graphics, logos, and images, is the property of Native Roots or its licensors and is protected by copyright laws.', 'b. You may not reproduce, modify, distribute, or display any part of our Services without our prior written consent.']
            },
            {
                heading:'6. Disclaimer of Warranties',
                info:['a. Our Services are provided on an "as is" and "as available" basis without any warranties of any kind, express or implied.', 'b. We do not warrant that our Services will be uninterrupted, secure, or error-free.']
            },
            {
                heading:'7. Limitation of Liability',
                info:['In no event shall Native Roots be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our Services.']
            },
            {
                heading:'8. Indemnification',
                info:['You agree to indemnify and hold harmless Native Roots, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, or expenses arising out of your use of our Services or your violation of these Terms.']
            },
            {
                heading:'9. Modification of Terms',
                info:['We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our website or mobile application. Your continued use of our Services after the posting of any modified Terms constitutes your acceptance of the changes.']
            },
            {
                heading:'10. Governing Law',
                info:['These Terms shall be governed by and construed in accordance with the laws of India.']
            },
            {
                heading:'11. Contact Us',
                info:['If you have any questions or concerns about these Terms, please contact us at [customercare@Nativeroots.in].']
            },
        ]
    }
  )

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
        <div className={`pb-1p p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
        <div className="overflow-y-auto p-2.5">
            <div className={`p-4 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                <h1 className="font-bold text-lg mb-1.5" style={{ color: colors?.heading }}>{data?.title}</h1>
                <p className="text-sm text-justify" style={{ color: colors?.heading }}>{data?.description}</p>
            </div>
            {
                data.details.map((item, index) => (
                    <div key={`_${index}PrivacyPolicy`} className={`p-4 mt-2.5 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                        <h2 className="font-bold text-lg" style={{ color: colors?.heading }}>{item?.heading}</h2>
                        {
                            item?.info.map((value, index) => (
                                <p key={`_${index}PrivacyPolicy1`} className="text-left text-sm mt-1.5" style={{ color: colors?.heading }}>{value}</p>
                            ))
                        }
                    </div>
                ))
            }
        </div>
        {/* <Footer/> */}
        </div>
    </>
  )
}
