import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import UseCookie from '../../utils/cookies/useCookie';

import { en } from '../en';
import { zh } from '../zh';


// const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();

const languageResources = {
  en: { translation: en },
  zh: { translation: zh },
};

i18next
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    lng: 'en', // Set your default language key here
    fallbackLng: 'en',
    resources: languageResources,
  });

// const getStoredLanguage = async () => {
//   const lng = await getCustomCookie('appLanguage');
//   //console.log("appLanguage-->", lng);

//   i18next
//     .use(initReactI18next)
//     .init({
//       compatibilityJSON: 'v3',
//       lng: lng || 'en', // Use 'en' as a default if no language is stored
//       fallbackLng: 'en',
//       resources: languageResources,
//     });
// };



export default i18next;
